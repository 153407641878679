import moment from "moment";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { display, spacing } from "@material-ui/system";
import ReactCompareImage from "react-compare-image";
import TodayIcon from "@material-ui/icons/Today";
import Controls from "../../components/controls";
import CloseIcon from "@material-ui/icons/Close";
import {
  Box as MuiBox,
  AppBar,
  Grid,
  Card as MuiCard,
  CardHeader,
  CardContent,
  Paper as MuiPaper,
  IconButton,
  Divider,
  Tab,
  Slide,
  Tabs,
  List,
  ListItem,
  ListItemText,
  Typography,
  Toolbar,
  ListItemIcon,
  ListItemSecondaryAction,
  Avatar,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  Checkbox,
  InputLabel,
} from "@material-ui/core";

import leftImage from "./body_positions/left.png";
import frontImage from "./body_positions/front.png";
import backImage from "./body_positions/back.png";
import rightImage from "./body_positions/right.png";
import CameraHD from "../../components/CameraHD";
import Popup from "../../components/Popup";
import CompareIcon from "@material-ui/icons/Compare";
import ImagesGroupForm from "../../components/forms/ImagesGroupForm";
import Api from "../../lib/api";

import Iconify from "../../components/Iconify";
import LoadingScreen from "../../components/LoadingScreen";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import CRMUtils from "../../utils";
import { borderRadius, padding } from "polished";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Card = styled(MuiCard)`
  ${spacing};
  box-shadow: none;
`;

const Box = styled(MuiBox)`
  ${spacing};
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  ${spacing};
  height: 100%;
`;
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const useStyles = makeStyles((theme) => ({
  imagePosition: {
    maxHeight: "150px",
    cursor: "pointer",
  },
  divider: {
    margin: "10px",
  },
  imagesContainer: {
    position: "relative",
    marginTop: "-45px",
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  imagePositionWrapper: {
    width: "50%",
    alignSelf: "center",
    justifySelf: "center",
  },
  selectedPosition: {
    border: "1px solid blue",
  },
  bigImage: {
    cursor: "pointer",
    width: "auto",
    height: "380px",
    borderRadius: 15,
    marginTop: 7,
    boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
  },
  bigImage2: {
    cursor: "pointer",
    width: "auto",
    height: "380px",
    maxWidth: "93%",
    borderRadius: 15,
    marginTop: 7,
    boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  compareImages: {
    position: "absolute",
    top: "50%",
    left: "calc(50%-10px)",
  },
  count: {
    color: "#FFFFFF",
    backgroundColor: "#ab47bc",
  },
  resizableContainer: {
    '& [data-testid="container"]': {
      maxHeight: "600px !important",
    },
  },
  tabRoot: {
    flexDirection: "row",
    "& > span": {
      alignItems: "center",
    },
  },
  tabWrapper: {
    flexDirection: "row",
    "& > *:first-child": {
      marginBottom: "0 !important",
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "#e3f2fd",
    color: "#000",
    borderRadius: 15,
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px",
    height: "9vh",
  },
  customBadge: {
    backgroundColor: "#673ab7",
    color: "white",
  },
  moveOrCopyIcons: {
    fontSize: 20,
    color: "rgb(13, 71, 161)",
  },
  moveOrCopyIconButton: {
    padding: 6,
    marginRight: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function RecordImages(props) {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { authUser } = useSelector((state) => state.auth);

  const { record, userCanEdit } = props;

  const [isSaving, setIsSaving] = useState(false);

  const [before, setBefore] = useState(false);
  const [beforeObj, setBeforeObj] = useState(null);
  const [after, setAfter] = useState(false);
  const [afterObj, setAfterObj] = useState(null);
  const [position, setPosition] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const [openImagesGroup, setOpenImagesGroup] = useState(false);
  const [pictureType, setPictureType] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [entityForEdit, setEntityForEdit] = useState(null);
  const [procedures, setProcedures] = useState([]);

  const [changingPictureModel, setChangingPictureModel] = useState(null);

  const [openPopupShowImage, setOpenPopupShowImage] = useState(false);
  const [showImage, setShowImage] = useState(null);

  const [proceduresBAOthers, setProceduresBAOthers] = useState([]);

  const [proceduresBAOR, setProceduresBAOR] = useState([]);
  const [selectedBAORImages, setSelectedBAORImages] = useState([]);
  const [selectedBAOthersImages, setSelectedBAOthersImages] = useState([]);

  const [images, setImages] = useState([]);
  const [isMoveOrCopyActiveOnBAOR, setIsMoveOrCopyActiveOnBAOR] =
    useState(false);
  const [isMoveOrCopyActiveOnBAOthers, setIsMoveOrCopyActiveOnBAOthers] =
    useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const userCanMoveOrCopyORPhotos = CRMUtils.userHasPermission(authUser, [
    "record_moveorcopybaorimages",
  ]);
  const userCanMoveOrCopyOthersPhotos = CRMUtils.userHasPermission(authUser, [
    "record_moveorcopybaotherimages",
  ]);
  const openShowImage = (event, image, model) => {
    setChangingPictureModel(model);
    setShowImage(image);
    setOpenPopupShowImage(true);
  };

  const onCloseShowImage = () => {
    setOpenPopupShowImage(false);
    setShowImage(null);
    setChangingPictureModel(null);
  };

  const handleListItemClick = (event, index) => {
    setPosition(null);
    setBefore(null);
    setBeforeObj(null);
    setAfter(null);
    setAfterObj(null);
    setSelectedIndex(index);
    const procedure = procedures.find((procedure) => procedure.id === index);
    setImages(procedure.contactImages.data);
  };

  const switchImages = async (event, image, to, model) => {
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
    });

    setIsSaving(true);
    try {
      const payload = {
        name: `${image.name.split("_")[0]}_${to}`,
        type: to,
      };

      if (model === "OR" && image) {
        const { data } = await Api.updateContactImagesOR(image.id, payload);

        if (data && data.data) {
          getRecordProductsBAOR();
          setIsSaving(false);

          setNotify({
            isOpen: true,
            message: "Image successfully updated",
            type: "success",
          });
        }
      } else if (model === "OTHER" && image) {
        const { data } = await Api.updateContactImagesOther(image.id, payload);

        if (data && data.data) {
          getRecordProductsBAOthers();
          setIsSaving(false);

          setNotify({
            isOpen: true,
            message: "Image successfully updated",
            type: "success",
          });
        }
      }
    } catch (error) {
      setIsSaving(false);
      setNotify({
        isOpen: true,
        message: "Error updating the image. Please try again",
        type: "error",
      });
    }
  };

  const countImages = (position) => {
    if (images) {
      const imagesFiltered = images.filter((image) => {
        return image.position == position;
      });

      return imagesFiltered.length;
    } else return 0;
  };

  const deleteImageSelected = async () => {
    if (showImage) {
      if (changingPictureModel === "Before and After By Position") {
        const { data } = await Api.deleteImageWeb(showImage.id);

        if (data && data.message) {
          if (pictureType == "before") {
            setBefore(null);
            setBeforeObj(null);
          } else if (pictureType == "after") {
            setAfter(null);
            setAfterObj(null);
          }
          onCloseShowImage();
          getRecordProducts();
        }
      } else if (changingPictureModel === "OR Photos") {
        const { data } = await Api.deleteImageWebOR(showImage.id);

        if (data && data.message) {
          onCloseShowImage();
          getRecordProductsBAOR();
        }
      } else if (changingPictureModel === "Before and After Others") {
        const { data } = await Api.deleteImageWebOTher(showImage.id);

        if (data && data.message) {
          onCloseShowImage();
          getRecordProductsBAOthers();
        }
      }
    }
  };

  const onCaptureImage = async (img, file) => {
    setIsSaving(true);

    const formData = new FormData();
    formData.append("file", file);

    if (showImage) {
      if (changingPictureModel === "Before and After By Position") {
        const { data } = await Api.updateContactImages(showImage.id, formData);

        if (data && data.data) {
          if (pictureType == "before") {
            setBefore(img);
            setBeforeObj(data.data);
          } else if (pictureType == "after") {
            setAfter(img);
            setAfterObj(data.data);
          }
          setShowImage(data.data);
          getRecordProducts();
        }
      } else if (changingPictureModel === "OR Photos") {
        const { data } = await Api.updateContactImagesOR(
          showImage.id,
          formData
        );

        if (data && data.data) {
          setShowImage(data.data);
          getRecordProductsBAOR();
        }
      } else if (changingPictureModel === "Before and After Others") {
        const { data } = await Api.updateContactImagesOther(
          showImage.id,
          formData
        );

        if (data && data.data) {
          setShowImage(data.data);
          getRecordProductsBAOthers();
        }
      }
    } else {
      formData.append(
        "name",
        `${record.fname + " " + record.fname}_${pictureType}_${position}`
      );

      if (
        changingPictureModel === "Before and After By Position" ||
        changingPictureModel === "OR Photos"
      ) {
        formData.append("building_id", authUser.building_id);
        formData.append("organization_id", authUser.organization_id);
        formData.append("record_products_id", selectedIndex);
        formData.append("position", position);
        formData.append("type", pictureType);
        formData.append("image", "before_after");
      }

      if (changingPictureModel === "Before and After By Position") {
        const { data } = await Api.storeImageWeb(record.contact_id, formData);

        if (data && data.data) {
          if (pictureType == "before") {
            setBefore(img);
            setBeforeObj(data.data);
          } else if (pictureType == "after") {
            setAfter(img);
            setAfterObj(data.data);
          }
          setShowImage(data.data);
          getRecordProducts();
        }
      } else if (changingPictureModel === "OR Photos") {
        const { data } = await Api.storeImageORWeb(record.contact_id, formData);

        setShowImage(data.data);
        getRecordProducts();
      } else if (changingPictureModel === "Before and After Others") {
        const { data } = await Api.storeImageOtherWeb(
          record.contact_id,
          formData
        );

        setShowImage(data.data);
        getRecordProducts();
      }
    }
    setIsSaving(false);
    onCloseCamera();
  };

  const onCloseCamera = () => {
    setOpenCamera(false);
  };

  const onCloseCompare = () => {
    setOpenCompare(false);
  };

  const onCloseImagesGroup = () => {
    setOpenImagesGroup(false);
  };

  const takePicture = (type) => {
    if (userCanEdit) {
      if (!position || !selectedIndex) {
        alert("Procedure and Position need to be selected before");
        return;
      }
      setPictureType(type);
      setOpenCamera(true);
    }
  };

  const takePicture2 = () => {
    if (userCanEdit) {
      if (
        changingPictureModel === "Before and After By Position" &&
        (!position || !selectedIndex)
      ) {
        alert("Procedure and Position need to be selected before");
        return;
      }
      setOpenCamera(true);
    }
  };

  const addOrEdit = async (payload) => {
    payload.contact_id = record.contact_id;

    if (entityForEdit) {
    } else {
      if (!isEmpty(authUser)) {
        const { data } = await Api.storeImagesGroup(record.contact_id, payload);
      }
    }
  };

  // const getImagesGroups = async () => {
  //   const { data } = await Api.getImagesGroup(record.contact_id);
  //   setProcedures(data.data);
  // };

  const getRecordProducts = async () => {
    const { data } = await Api.getRecordProductsImages(record.id);
    setProcedures(data.data);
    if (selectedIndex) {
      const procedure = data.data.find(
        (procedure) => procedure.id === selectedIndex
      );
      // console.log("procedure.contactImages.data", procedure.contactImages);
      setImages(procedure.contactImages.data);
    }
  };

  const getRecordProductsBAOthers = async () => {
    const { data } = await Api.getRecordProductsImagesOthers(record.id);
    if (data && data.data) {
      setProceduresBAOthers(data.data);
    }
  };
  const getRecordProductsBAOR = async () => {
    const { data } = await Api.getRecordProductsImagesOR(record.id);
    if (data && data.data) {
      setProceduresBAOR(data.data);
    }
  };

  const renderProcedures = () => {
    return procedures.map((procedure) => {
      return (
        <ListItem
          key={procedure.id}
          button
          selected={selectedIndex === procedure.id}
          onClick={(event) => handleListItemClick(event, procedure.id)}
          style={{ borderRadius: 8 }}
        >
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="body1">{procedure.name}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.count}>
                    {procedure.images_count}
                  </Avatar>
                </Grid>
              </Grid>
            }
          />
          <ListItemSecondaryAction></ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  const selectPosition = (position) => {
    setPosition(position);
    setBefore(null);
    setBeforeObj(null);
    setAfter(null);
    setAfterObj(null);
    loadImage(position);
  };

  const loadImage = (position) => {
    const imageBefore = images.filter((image) => {
      return (
        image.position == position &&
        image.type == "before" &&
        !isEmpty(image.imageUrl)
      );
    });

    const imageAfter = images.filter((image) => {
      return (
        image.position == position &&
        image.type == "after" &&
        !isEmpty(image.imageUrl)
      );
    });

    if (!isEmpty(imageBefore)) {
      setBefore(imageBefore[0].imageUrl);

      setBeforeObj(imageBefore[0]);
    }

    if (!isEmpty(imageAfter)) {
      setAfter(imageAfter[0].imageUrl);
      setAfterObj(imageAfter[0]);
    }
  };

  useEffect(() => {
    if (value === 0) {
      getRecordProducts();
    } else if (value === 1) {
      getRecordProductsBAOR();
    } else {
      getRecordProductsBAOthers();
    }
  }, [value]);

  const isActiveTab = (index) => value === index;

  const updateSelectedBAORImages = (id) => {
    if (selectedBAORImages.includes(id)) {
      setSelectedBAORImages([...selectedBAORImages.filter((i) => i != id)]);
    } else {
      setSelectedBAORImages([...selectedBAORImages, id]);
    }
  };

  const updateSelectedBAOthersImages = (id) => {
    if (selectedBAOthersImages.includes(id)) {
      setSelectedBAOthersImages([
        ...selectedBAOthersImages.filter((i) => i != id),
      ]);
    } else {
      setSelectedBAOthersImages([...selectedBAOthersImages, id]);
    }
  };

  const closeConfirmDialog = (setConfirmDialogObj, confirmDialogObj) => {
    setConfirmDialogObj({
      ...confirmDialogObj,
      isOpen: false,
    });
  };

  const onMoveOrCopyOR = async (action = null) => {
    try {
      setConfirmDialogLoading(true);
      var payload = {
        action: action,
        images: [...selectedBAORImages],
      };

      const { data } = await Api.moveOrCopyBAORsImagesToOthersImages(payload);
      await getRecordProductsBAOR();
      setNotify({
        isOpen: true,
        message: data.message,
        type: "success",
      });
      setSelectedBAORImages([]);
      setIsMoveOrCopyActiveOnBAOR(false);
    } catch (error) {
      if (error.status == 401) {
        setNotify({
          isOpen: true,
          message: "Forbidden",
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setConfirmDialogLoading(false);
      closeConfirmDialog(setConfirmDialog, confirmDialog);
    }
  };

  const onMoveOrCopyOthers = async (action = null) => {
    try {
      setConfirmDialogLoading(true);
      var payload = {
        action: action,
        images: [...selectedBAOthersImages],
      };

      const { data } = await Api.moveOrCopyBAOtherImagesToORsImages(payload);
      await getRecordProductsBAOthers();
      setNotify({
        isOpen: true,
        message: data.message,
        type: "success",
      });
      setSelectedBAOthersImages([]);
      setIsMoveOrCopyActiveOnBAOthers(false);
    } catch (error) {
      if (error.status == 401) {
        setNotify({
          isOpen: true,
          message: "Forbidden",
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setConfirmDialogLoading(false);
      closeConfirmDialog(setConfirmDialog, confirmDialog);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Record Images" />
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="icon label tabs"
          TabIndicatorProps={{ style: { visibility: "hidden" } }}
          style={{ marginLeft: 20 }}
        >
          <Tab
            icon={
              <Iconify
                icon="dashicons:image-flip-horizontal"
                width={22}
                height={22}
              />
            }
            label="Before and After By Position"
            classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
            style={{ color: isActiveTab(0) ? "#0d47a1" : "#78909c" }}
          />
          <Tab
            icon={
              <Iconify icon="entypo:folder-images" width={22} height={22} />
            }
            label="OR Photos"
            classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
            style={{ color: isActiveTab(1) ? "#0d47a1" : "#78909c" }}
          />
          <Tab
            icon={<Iconify icon="ion:images" width={22} height={22} />}
            label="Before and After Others"
            classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
            style={{ color: isActiveTab(2) ? "#0d47a1" : "#78909c" }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} {...a11yProps(0)}>
        <Grid container spacing={8}>
          <Grid item xs={8} className={classes.imagesWrapper}>
            <Grid
              container
              spacing={6}
              style={{ alignItems: "center", justifyContent: "center" }}
              className={classes.imagesContainer}
            >
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{
                    borderRadius: 15,
                    backgroundColor: "#2196f3",
                    textAlign: "center",
                    padding: 10,
                    color: "#ffffff",
                  }}
                >
                  Before
                </Typography>

                <Box display="flex" justifyContent="center" alignItems="center">
                  {before ? (
                    <img
                      alt="Before"
                      src={before}
                      onClick={(event) => {
                        setPictureType("before");
                        openShowImage(
                          event,
                          beforeObj,
                          "Before and After By Position"
                        );
                      }}
                      className={classes.bigImage2}
                    />
                  ) : (
                    <div
                      className={classes.bigImage2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Iconify
                        icon="streamline:camera-disabled"
                        width={200}
                        height={200}
                        onClick={(event) => {
                          if (position && selectedIndex) {
                            setPictureType("before");
                            openShowImage(
                              event,
                              beforeObj,
                              "Before and After By Position"
                            );
                          }
                        }}
                        // onClick={() => takePicture("before")}
                        style={{
                          color: "#607d8b",
                          boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
                          padding: 25,
                        }}
                      />
                    </div>
                  )}
                </Box>
              </Grid>

              {before && after && (
                <div className={classes.compareImages}>
                  <IconButton
                    color="primary"
                    aria-label="compare pictures"
                    component="span"
                    onClick={() => setOpenCompare(true)}
                  >
                    <CompareIcon />
                  </IconButton>
                </div>
              )}
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{
                    borderRadius: 15,
                    backgroundColor: "#009688",
                    textAlign: "center",
                    padding: 10,
                    color: "#ffffff",
                  }}
                >
                  After
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {after ? (
                    <img
                      alt="After"
                      src={after}
                      onClick={(event) => {
                        setPictureType("after");
                        openShowImage(
                          event,
                          afterObj,
                          "Before and After By Position"
                        );
                      }}
                      className={classes.bigImage2}
                    />
                  ) : (
                    <div
                      className={classes.bigImage2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Iconify
                        icon="streamline:camera-disabled"
                        width={200}
                        height={200}
                        onClick={(event) => {
                          if (position && selectedIndex) {
                            setPictureType("after");
                            openShowImage(
                              event,
                              afterObj,
                              "Before and After By Position"
                            );
                          }
                        }}
                        // onClick={() => takePicture("after")}
                        style={{
                          color: "#607d8b",
                          boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
                          padding: 25,
                        }}
                      />
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid
              container
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 25,
              }}
            >
              <Grid item>
                <Typography variant="h4">Positions</Typography>
              </Grid>

              {!selectedIndex || !position ? (
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ color: "#4db6ac", marginLeft: 50 }}
                  >
                    *** Please Select a Position ***
                  </Typography>
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            <Card className={classes.imagePositionWrapper}>
              <Grid
                container
                spacing={4}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={
                      position == "left" ? classes.selectedPosition : ""
                    }
                  >
                    <Badge
                      badgeContent={images ? countImages("left") : 0}
                      classes={{ badge: classes.customBadge }}
                      style={{ marginTop: "10px" }}
                    >
                      <img
                        src={leftImage}
                        className={classes.imagePosition}
                        onClick={() => selectPosition("left")}
                      />
                    </Badge>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={
                      position == "front" ? classes.selectedPosition : ""
                    }
                  >
                    <Badge
                      badgeContent={images ? countImages("front") : 0}
                      classes={{ badge: classes.customBadge }}
                      style={{ marginTop: "10px" }}
                    >
                      <img
                        src={frontImage}
                        className={classes.imagePosition}
                        onClick={() => selectPosition("front")}
                      />
                    </Badge>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={
                      position == "back" ? classes.selectedPosition : ""
                    }
                  >
                    <Badge
                      badgeContent={images ? countImages("back") : 0}
                      classes={{ badge: classes.customBadge }}
                      style={{ marginTop: "10px" }}
                    >
                      <img
                        src={backImage}
                        className={classes.imagePosition}
                        onClick={() => selectPosition("back")}
                      />
                    </Badge>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={
                      position == "right" ? classes.selectedPosition : ""
                    }
                  >
                    <Badge
                      badgeContent={images ? countImages("right") : 0}
                      classes={{ badge: classes.customBadge }}
                      style={{ marginTop: "10px" }}
                    >
                      <img
                        src={rightImage}
                        className={classes.imagePosition}
                        onClick={() => selectPosition("right")}
                      />
                    </Badge>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                style={{ justifyContent: "flex-start", alignItems: "center" }}
              >
                <Grid item>
                  <Typography variant="h4">Procedures</Typography>
                </Grid>
                {!selectedIndex && (
                  <Grid item>
                    <Typography
                      variant="body1"
                      style={{ color: "#4db6ac", marginLeft: 50 }}
                    >
                      *** Please Select a Procedure ***
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Toolbar>

            <List component="nav" aria-label="images">
              {renderProcedures()}
            </List>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1} {...a11yProps(1)}>
        {!isEmpty(proceduresBAOR) ? (
          <div>
            {userCanMoveOrCopyORPhotos ? (
              <div>
                {!isMoveOrCopyActiveOnBAOR ? (
                  <Button
                    onClick={() => {
                      setIsMoveOrCopyActiveOnBAOR(true);
                    }}
                    startIcon={<Iconify icon="iconamoon:copy-fill" />}
                    style={{ color: "rgb(13, 71, 161)" }}
                  >
                    Move or copy images to "Before and After Others"
                  </Button>
                ) : (
                  <div>
                    <Button
                      disabled={selectedBAORImages.length == 0}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to copy the selected images?",
                          subTitle: "",
                          onConfirm: () => {
                            onMoveOrCopyOR("copy");
                          },
                        });
                      }}
                      style={{
                        color:
                          selectedBAORImages.length == 0
                            ? "#898989"
                            : "rgb(13, 71, 161)",
                      }}
                      startIcon={
                        <Iconify
                          className={classes.moveOrCopyIcons}
                          style={{
                            color:
                              selectedBAORImages.length == 0
                                ? "#898989"
                                : "rgb(13, 71, 161)",
                          }}
                          icon="gridicons:image-multiple"
                        />
                      }
                    >
                      Copy
                    </Button>
                    <Button
                      disabled={selectedBAORImages.length == 0}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to move the selected images?",
                          subTitle: "",
                          onConfirm: () => {
                            onMoveOrCopyOR("move");
                          },
                        });
                      }}
                      style={{
                        color:
                          selectedBAORImages.length == 0
                            ? "#898989"
                            : "rgb(13, 71, 161)",
                      }}
                      startIcon={
                        <Iconify
                          className={classes.moveOrCopyIcons}
                          style={{
                            color:
                              selectedBAORImages.length == 0
                                ? "#898989"
                                : "rgb(13, 71, 161)",
                          }}
                          icon="mdi:image-move"
                        />
                      }
                    >
                      Move
                    </Button>
                    <Button
                      style={{ color: "rgb(0 0 0)" }}
                      onClick={() => {
                        setIsMoveOrCopyActiveOnBAOR(false);
                        setSelectedBAORImages([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {proceduresBAOR.map((procedure, index) => {
              const beforeIMGOR = procedure?.contactImagesORs?.data.filter(
                (item) => item.type === "before"
              );
              const afterIMGOR = procedure?.contactImagesORs?.data.filter(
                (item) => item.type === "after"
              );

              const beforeIMGORAllSelected =
                !beforeIMGOR.find(
                  (img) => !selectedBAORImages.includes(img.id)
                ) && selectedBAORImages.length > 0;

              const afterIMGORAllSelected =
                !afterIMGOR.find(
                  (img) => !selectedBAORImages.includes(img.id)
                ) && selectedBAORImages.length > 0;
              return (
                <MuiCard
                  key={`card-${procedure?.id}-${index}`}
                  style={{ paddingBottom: 10 }}
                >
                  <CardHeader
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6">{procedure?.name}</Typography>
                        {isMoveOrCopyActiveOnBAOR ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              id="checkbox_all"
                              checked={
                                beforeIMGORAllSelected && afterIMGORAllSelected
                              }
                              onClick={() => {
                                if (
                                  beforeIMGORAllSelected &&
                                  afterIMGORAllSelected
                                ) {
                                  setSelectedBAORImages([
                                    ...selectedBAORImages.filter(
                                      (img) =>
                                        beforeIMGOR.findIndex(
                                          (i) => i.id == img
                                        ) == -1 &&
                                        afterIMGOR.findIndex(
                                          (i) => i.id == img
                                        ) == -1
                                    ),
                                  ]);
                                } else {
                                  let set = new Set([
                                    ...selectedBAORImages,
                                    ...beforeIMGOR.map((c) => c.id),
                                    ...afterIMGOR.map((c) => c.id),
                                  ]);
                                  setSelectedBAORImages([...set]);
                                }
                              }}
                            />
                            <InputLabel style={{ color: "black" }}>
                              All
                            </InputLabel>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                    subheader={`Total: ${procedure?.contact_images_ors_count}`}
                  />
                  <CardContent>
                    {beforeIMGOR.length > 0 && (
                      <>
                        <Box
                          style={{
                            paddingLeft: 25,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          {/* <Typography
                            variant="h6"
                            style={{
                              borderRadius: 15,
                              backgroundColor: "#2196f3",
                              textAlign: "center",
                              padding: 3,
                              color: "#ffffff",
                              width: "8%",
                            }}
                          >
                            Before
                          </Typography> */}
                          {isMoveOrCopyActiveOnBAOR ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Checkbox
                                id="before_all"
                                checked={beforeIMGORAllSelected}
                                onClick={() => {
                                  if (beforeIMGORAllSelected) {
                                    setSelectedBAORImages([
                                      ...selectedBAORImages.filter(
                                        (img) =>
                                          beforeIMGOR.findIndex(
                                            (i) => i.id == img
                                          ) == -1
                                      ),
                                    ]);
                                  } else {
                                    let set = new Set([
                                      ...selectedBAORImages,
                                      ...beforeIMGOR.map((c) => c.id),
                                    ]);
                                    setSelectedBAORImages([...set]);
                                  }
                                }}
                              />
                              <InputLabel style={{ color: "black" }}>
                                All
                              </InputLabel>
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Grid
                          container
                          style={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {beforeIMGOR.map((image, indexImage) => {
                            const selected = selectedBAORImages.includes(
                              image.id
                            );
                            return (
                              <Grid
                                item
                                xs={2}
                                key={`box-before-${indexImage}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -15,
                                      right: 43,
                                    }}
                                  >
                                    <Tooltip title={image?.created_at}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Iconify
                                          icon={
                                            "solar:clock-circle-bold-duotone"
                                          }
                                          width={40}
                                          height={40}
                                          style={{
                                            color: "#009688",
                                            padding: 2,
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selected
                                      ? "#e5ebf1"
                                      : "white",
                                    padding: "8px 0px",
                                    marginRight: 5,
                                  }}
                                >
                                  {isMoveOrCopyActiveOnBAOR ? (
                                    <Checkbox
                                      checked={selected}
                                      onClick={() => {
                                        updateSelectedBAORImages(image.id);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    src={image?.thumbUrl}
                                    alt={image?.name}
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      maxHeight: "150px",
                                      maxWidth: "150px",
                                      borderRadius: 15,
                                      boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) =>
                                      openShowImage(event, image, "OR Photos")
                                    }
                                  />
                                </div>
                                <div>
                                  <ListItemText
                                    primary={image?.comments || " "}
                                    secondary="Click to see more details"
                                    primaryTypographyProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: 13,
                                        textAlign: "center",
                                        maxWidth: '90%',
                                        fontFamily:
                                          '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                                      },
                                    }}
                                    secondaryTypographyProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        fontSize: 11,
                                        maxWidth: "100%",
                                        textAlign: "center",
                                        fontStyle: "italic",
                                        fontFamily:
                                          '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                                      },
                                    }}
                                  />
                                </div>
                                {/* <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -35,
                                      right: 11,
                                    }}
                                  >
                                     <Tooltip title="Move to After">
                                      <div>
                                        <Controls.IconButton
                                          onClick={() => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                "Are you sure you want to move to 'After' this image?",
                                              subTitle: "",
                                              onConfirm: () => {
                                                switchImages(
                                                  event,
                                                  image,
                                                  "After",
                                                  "OR"
                                                );
                                              },
                                            });
                                          }}
                                          style={{
                                            backgroundColor: "#009688",
                                            boxShadow:
                                              "0 0 10px 0 rgb(53 64 82 / 21%)",
                                          }}
                                        >
                                          <Iconify
                                            icon={"tabler:camera-down"}
                                            width={32}
                                            height={32}
                                            style={{
                                              color: "#ffffff",
                                              padding: 2,
                                            }}
                                          />
                                        </Controls.IconButton>
                                      </div>
                                    </Tooltip> 
                                  </div>
                                </div>*/}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                    {afterIMGOR.length > 0 && (
                      <>
                        <Box
                          style={{
                            paddingLeft: 25,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          {/* <Typography
                            variant="h6"
                            style={{
                              borderRadius: 15,
                              backgroundColor: "#009688",
                              textAlign: "center",
                              padding: 3,
                              color: "#ffffff",
                              width: "8%",
                              marginTop: 15,
                              marginBottom: 10,
                            }}
                          >
                            After
                          </Typography> */}
                          {isMoveOrCopyActiveOnBAOR ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Checkbox
                                id="after_all"
                                checked={afterIMGORAllSelected}
                                onClick={() => {
                                  if (afterIMGORAllSelected) {
                                    setSelectedBAORImages([
                                      ...selectedBAORImages.filter(
                                        (img) =>
                                          afterIMGOR.findIndex(
                                            (i) => i.id == img
                                          ) == -1
                                      ),
                                    ]);
                                  } else {
                                    let set = new Set([
                                      ...selectedBAORImages,
                                      ...afterIMGOR.map((c) => c.id),
                                    ]);
                                    setSelectedBAORImages([...set]);
                                  }
                                }}
                              />
                              <InputLabel style={{ color: "black" }}>
                                All
                              </InputLabel>
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Grid
                          container
                          style={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {afterIMGOR.map((image, indexImage) => {
                            const selected = selectedBAORImages.includes(
                              image.id
                            );
                            return (
                              <Grid item xs={2} key={`box-after-${indexImage}`}>
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -15,
                                      right: 43,
                                    }}
                                  >
                                    <Tooltip title={image?.created_at}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Iconify
                                          icon={
                                            "solar:clock-circle-bold-duotone"
                                          }
                                          width={40}
                                          height={40}
                                          style={{
                                            color: "#009688",
                                            padding: 2,
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selected
                                      ? "#e5ebf1"
                                      : "white",
                                    padding: "8px 0px",
                                    marginRight: 5,
                                  }}
                                >
                                  {isMoveOrCopyActiveOnBAOR ? (
                                    <Checkbox
                                      checked={selected}
                                      onClick={() => {
                                        updateSelectedBAORImages(image.id);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    src={image?.thumbUrl}
                                    alt={image?.name}
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      maxHeight: "150px",
                                      maxWidth: "150px",
                                      borderRadius: 15,
                                      boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) =>
                                      openShowImage(event, image, "OR Photos")
                                    }
                                  />
                                </div>
                                <div>
                                  <ListItemText
                                    primary={image?.comments || " "}
                                    secondary="Click to see more details"
                                    primaryTypographyProps={{
                                      style: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: 13,
                                        textAlign: "center",
                                        maxWidth: '90%',
                                        fontFamily:
                                          '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                                      },
                                    }}
                                    secondaryTypographyProps={{
                                      style: {
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        fontSize: 11,
                                        maxWidth: "100%",
                                        textAlign: "center",
                                        fontStyle: "italic",
                                        fontFamily:
                                          '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                                      },
                                    }}
                                  />
                                </div>
                                {/* 
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -35,
                                      right: 11,
                                    }}
                                  >
                                  <Tooltip title="Move to Before">
                                      <div>
                                        <Controls.IconButton
                                          onClick={() => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                "Are you sure you want to move to 'Before' this image?",
                                              subTitle: "",
                                              onConfirm: () => {
                                                switchImages(
                                                  event,
                                                  image,
                                                  "Before",
                                                  "OR"
                                                );
                                              },
                                            });
                                          }}
                                          style={{
                                            backgroundColor: "#009688",
                                            boxShadow:
                                              "0 0 10px 0 rgb(53 64 82 / 21%)",
                                          }}
                                        >
                                          <Iconify
                                            icon={"tabler:camera-up"}
                                            width={32}
                                            height={32}
                                            style={{
                                              color: "#ffffff",
                                              padding: 2,
                                            }}
                                          />
                                        </Controls.IconButton>
                                      </div>
                                    </Tooltip> 
                                  </div>
                               
                                </div>
                               */}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </MuiCard>
              );
            })}
          </div>
        ) : (
          <Box style={{ minHeight: "55vh" }}>
            <Typography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                marginTop: 50,
                textAlign: "center",
              }}
            >
              There are no images available for display.
            </Typography>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={2} {...a11yProps(2)}>
        {!isEmpty(proceduresBAOthers) ? (
          <div>
            {userCanMoveOrCopyOthersPhotos ? (
              <div>
                {!isMoveOrCopyActiveOnBAOthers ? (
                  <Button
                    onClick={() => {
                      setIsMoveOrCopyActiveOnBAOthers(true);
                    }}
                    startIcon={<Iconify icon="iconamoon:copy-fill" />}
                    style={{ color: "rgb(13, 71, 161)" }}
                  >
                    Move or copy images to "OR Photos"
                  </Button>
                ) : (
                  <div>
                    <Button
                      disabled={selectedBAOthersImages.length == 0}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to copy the selected images?",
                          subTitle: "",
                          onConfirm: () => {
                            onMoveOrCopyOthers("copy");
                          },
                        });
                      }}
                      style={{
                        color:
                          selectedBAOthersImages.length == 0
                            ? "#898989"
                            : "rgb(13, 71, 161)",
                      }}
                      startIcon={
                        <Iconify
                          className={classes.moveOrCopyIcons}
                          style={{
                            color:
                              selectedBAOthersImages.length == 0
                                ? "#898989"
                                : "rgb(13, 71, 161)",
                          }}
                          icon="gridicons:image-multiple"
                        />
                      }
                    >
                      Copy
                    </Button>
                    <Button
                      disabled={selectedBAOthersImages.length == 0}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to move the selected images?",
                          subTitle: "",
                          onConfirm: () => {
                            onMoveOrCopyOthers("move");
                          },
                        });
                      }}
                      style={{
                        color:
                          selectedBAOthersImages.length == 0
                            ? "#898989"
                            : "rgb(13, 71, 161)",
                      }}
                      startIcon={
                        <Iconify
                          className={classes.moveOrCopyIcons}
                          style={{
                            color:
                              selectedBAOthersImages.length == 0
                                ? "#898989"
                                : "rgb(13, 71, 161)",
                          }}
                          icon="mdi:image-move"
                        />
                      }
                    >
                      Move
                    </Button>
                    <Button
                      style={{ color: "rgb(0 0 0)" }}
                      onClick={() => {
                        setIsMoveOrCopyActiveOnBAOthers(false);
                        setSelectedBAOthersImages([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {proceduresBAOthers.map((procedure, index) => {
              const beforeIMG = procedure?.contactImagesOthers?.data.filter(
                (item) => item.type === "before"
              );
              const afterIMG = procedure?.contactImagesOthers?.data.filter(
                (item) => item.type === "after"
              );

              const beforeIMGOthersAllSelected =
                !beforeIMG.find(
                  (img) => !selectedBAOthersImages.includes(img.id)
                ) && selectedBAOthersImages.length > 0;

              const afterIMGOthersAllSelected =
                !afterIMG.find(
                  (img) => !selectedBAOthersImages.includes(img.id)
                ) && selectedBAOthersImages.length > 0;
              return (
                <MuiCard
                  key={`card-${procedure?.id}-${index}`}
                  style={{ paddingBottom: 10 }}
                >
                  <CardHeader
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6">{procedure?.name}</Typography>
                        {isMoveOrCopyActiveOnBAOthers ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              id="checkbox_all"
                              checked={
                                beforeIMGOthersAllSelected &&
                                afterIMGOthersAllSelected
                              }
                              onClick={() => {
                                if (
                                  beforeIMGOthersAllSelected &&
                                  afterIMGOthersAllSelected
                                ) {
                                  setSelectedBAOthersImages([
                                    ...selectedBAOthersImages.filter(
                                      (img) =>
                                        beforeIMG.findIndex(
                                          (i) => i.id == img
                                        ) == -1 &&
                                        afterIMG.findIndex(
                                          (i) => i.id == img
                                        ) == -1
                                    ),
                                  ]);
                                } else {
                                  let set = new Set([
                                    ...selectedBAOthersImages,
                                    ...beforeIMG.map((c) => c.id),
                                    ...afterIMG.map((c) => c.id),
                                  ]);
                                  setSelectedBAOthersImages([...set]);
                                }
                              }}
                            />
                            <InputLabel style={{ color: "black" }}>
                              All
                            </InputLabel>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                    subheader={`Total: ${procedure?.contact_images_others_count}`}
                  />
                  <CardContent>
                    {beforeIMG.length > 0 && (
                      <>
                        <Box
                          style={{
                            paddingLeft: 25,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              borderRadius: 15,
                              backgroundColor: "#2196f3",
                              textAlign: "center",
                              padding: 3,
                              color: "#ffffff",
                              width: "8%",
                            }}
                          >
                            Before
                          </Typography>
                          {isMoveOrCopyActiveOnBAOthers ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Checkbox
                                id="before_all"
                                checked={beforeIMGOthersAllSelected}
                                onClick={() => {
                                  if (beforeIMGOthersAllSelected) {
                                    setSelectedBAOthersImages([
                                      ...selectedBAOthersImages.filter(
                                        (img) =>
                                          beforeIMG.findIndex(
                                            (i) => i.id == img
                                          ) == -1
                                      ),
                                    ]);
                                  } else {
                                    let set = new Set([
                                      ...selectedBAOthersImages,
                                      ...beforeIMG.map((c) => c.id),
                                    ]);
                                    setSelectedBAOthersImages([...set]);
                                  }
                                }}
                              />
                              <InputLabel style={{ color: "black" }}>
                                All
                              </InputLabel>
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Grid
                          container
                          style={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {beforeIMG.map((image, indexImage) => {
                            const selected = selectedBAOthersImages.includes(
                              image.id
                            );
                            return (
                              <Grid
                                item
                                xs={2}
                                key={`box-before-${indexImage}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -15,
                                      right: 43,
                                    }}
                                  >
                                    <Tooltip title={image?.created_at}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Iconify
                                          icon={
                                            "solar:clock-circle-bold-duotone"
                                          }
                                          width={40}
                                          height={40}
                                          style={{
                                            color: "#009688",
                                            padding: 2,
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selected
                                      ? "#e5ebf1"
                                      : "white",
                                    padding: "8px 0px",
                                    marginRight: 5,
                                  }}
                                >
                                  {isMoveOrCopyActiveOnBAOthers ? (
                                    <Checkbox
                                      checked={selected}
                                      onClick={() => {
                                        updateSelectedBAOthersImages(image.id);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    src={image?.thumbUrl}
                                    alt={image?.name}
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      maxHeight: "150px",
                                      maxWidth: "150px",
                                      borderRadius: 15,
                                      boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) =>
                                      openShowImage(
                                        event,
                                        image,
                                        "Before and After Others"
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -35,
                                      right: 11,
                                    }}
                                  >
                                    <Tooltip title="Move to After">
                                      <div>
                                        <Controls.IconButton
                                          onClick={() => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                "Are you sure you want to move to 'After' this image?",
                                              subTitle: "",
                                              onConfirm: () => {
                                                switchImages(
                                                  event,
                                                  image,
                                                  "After",
                                                  "OTHER"
                                                );
                                              },
                                            });
                                          }}
                                          style={{
                                            backgroundColor: "#009688",
                                            boxShadow:
                                              "0 0 10px 0 rgb(53 64 82 / 21%)",
                                          }}
                                        >
                                          <Iconify
                                            icon={"tabler:camera-down"}
                                            width={32}
                                            height={32}
                                            style={{
                                              color: "#ffffff",
                                              padding: 2,
                                            }}
                                          />
                                        </Controls.IconButton>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                    {afterIMG.length > 0 && (
                      <>
                        <Box
                          style={{
                            paddingLeft: 25,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              borderRadius: 15,
                              backgroundColor: "#009688",
                              textAlign: "center",
                              padding: 3,
                              color: "#ffffff",
                              width: "8%",
                              marginTop: 15,
                              marginBottom: 10,
                            }}
                          >
                            After
                          </Typography>
                          {isMoveOrCopyActiveOnBAOthers ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Checkbox
                                id="after_all"
                                checked={afterIMGOthersAllSelected}
                                onClick={() => {
                                  if (afterIMGOthersAllSelected) {
                                    setSelectedBAOthersImages([
                                      ...selectedBAOthersImages.filter(
                                        (img) =>
                                          afterIMG.findIndex(
                                            (i) => i.id == img
                                          ) == -1
                                      ),
                                    ]);
                                  } else {
                                    let set = new Set([
                                      ...selectedBAOthersImages,
                                      ...afterIMG.map((c) => c.id),
                                    ]);
                                    setSelectedBAOthersImages([...set]);
                                  }
                                }}
                              />
                              <InputLabel style={{ color: "black" }}>
                                All
                              </InputLabel>
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Grid
                          container
                          style={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {afterIMG.map((image, indexImage) => {
                            const selected = selectedBAOthersImages.includes(
                              image.id
                            );
                            return (
                              <Grid item xs={2} key={`box-after-${indexImage}`}>
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -15,
                                      right: 43,
                                    }}
                                  >
                                    <Tooltip title={image?.created_at}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Iconify
                                          icon={
                                            "solar:clock-circle-bold-duotone"
                                          }
                                          width={40}
                                          height={40}
                                          style={{
                                            color: "#009688",
                                            padding: 2,
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selected
                                      ? "#e5ebf1"
                                      : "white",
                                    padding: "8px 0px",
                                    marginRight: 5,
                                  }}
                                >
                                  {isMoveOrCopyActiveOnBAOthers ? (
                                    <Checkbox
                                      checked={selected}
                                      onClick={() => {
                                        updateSelectedBAOthersImages(image.id);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    src={image?.thumbUrl}
                                    alt={image?.name}
                                    style={{
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      maxHeight: "150px",
                                      maxWidth: "150px",
                                      borderRadius: 15,
                                      boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) =>
                                      openShowImage(
                                        event,
                                        image,
                                        "Before and After Others"
                                      )
                                    }
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: 999,
                                      top: -35,
                                      right: 11,
                                    }}
                                  >
                                    <Tooltip title="Move to Before">
                                      <div>
                                        <Controls.IconButton
                                          onClick={() => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                "Are you sure you want to move to 'Before' this image?",
                                              subTitle: "",
                                              onConfirm: () => {
                                                switchImages(
                                                  event,
                                                  image,
                                                  "Before",
                                                  "OTHER"
                                                );
                                              },
                                            });
                                          }}
                                          style={{
                                            backgroundColor: "#009688",
                                            boxShadow:
                                              "0 0 10px 0 rgb(53 64 82 / 21%)",
                                          }}
                                        >
                                          <Iconify
                                            icon={"tabler:camera-up"}
                                            width={32}
                                            height={32}
                                            style={{
                                              color: "#ffffff",
                                              padding: 2,
                                            }}
                                          />
                                        </Controls.IconButton>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </MuiCard>
              );
            })}
          </div>
        ) : (
          <Box style={{ minHeight: "55vh" }}>
            <Typography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                marginTop: 50,
                textAlign: "center",
              }}
            >
              There are no images available for display.
            </Typography>
          </Box>
        )}
      </TabPanel>

      <Dialog
        aria-labelledby="camera"
        open={openCamera}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            minWidth: "45%",
          },
        }}
        onClose={onCloseCamera}
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
              }}
            >
              Upload/Capture Image
            </Typography>
            <Controls.IconButton
              onClick={onCloseCamera}
              style={{
                textAlign: "right",
                color: "#000000",
                fontWeight: 700,
              }}
            >
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ marginTop: 20, minHeight: "65vh" }}>
          <CameraHD onCapture={onCaptureImage} />
        </DialogContent>
      </Dialog>

      {/* <Popup
        title="Upload/Capture Image"
        openPopup={openCamera}
        onClose={onCloseCamera}
      >
        <CameraHD onCapture={onCaptureImage} />
      </Popup> */}
      <Popup
        title="Compare Images"
        fullWidth={true}
        openPopup={openCompare}
        onClose={onCloseCompare}
      >
        <div className={classes.resizableContainer}>
          <ReactCompareImage leftImage={before} rightImage={after} />
        </div>
      </Popup>
      <Popup
        title="Group Images"
        fullWidth={true}
        openPopup={openImagesGroup}
        onClose={onCloseImagesGroup}
      >
        <ImagesGroupForm groupForEdit={entityForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Dialog
        aria-labelledby="payroll-configuration-dialog"
        open={openPopupShowImage}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
          },
        }}
        onClose={onCloseShowImage}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#212b36",
                fontFamily: "Nunito,Roboto",
                fontWeight: 700,
                fontSize: "1.125rem",
                margin: 12,
              }}
            >
              {showImage
                ? `Image: ${showImage?.name.replace(/_/g, " ")}`
                : "Image"}
            </Typography>
            <Controls.IconButton
              onClick={onCloseShowImage}
              style={{
                textAlign: "right",
                color: "#000000",
                fontWeight: 700,
              }}
            >
              <CloseIcon />
            </Controls.IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{ marginTop: 15, minHeight: "75vh", marginBottom: 30 }}
        >
          <Grid
            container
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={10}>
              {showImage?.comments ? (
                <ListItemText
                  primary="Comments:"
                  secondary={showImage?.comments}
                  primaryTypographyProps={{
                    style: {
                      paddingLeft: 10,
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      fontSize: 15,
                      fontFamily:
                        '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                    },
                  }}
                  secondaryTypographyProps={{
                    style: {
                      paddingLeft: 10,
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      fontSize: 14,
                      maxWidth: "100%",
                      fontFamily:
                        '"Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
                    },
                  }}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={2}>
              <AppBar
                position="static"
                style={{
                  backgroundColor: "#e3f2fd",
                  borderRadius: 25,
                  marginBottom: 30,
                  boxShadow: "0 0 10px 0 rgb(53 64 82 / 21%)",
                  height: 80,
                }}
              >
                <Toolbar
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Controls.IconButton
                    onClick={() => {
                      if (
                        changingPictureModel === "Before and After By Position"
                      ) {
                        position && selectedIndex ? takePicture2() : false;
                      } else {
                        takePicture2();
                      }
                    }}
                  >
                    <Iconify
                      icon={"fluent:camera-sparkles-24-filled"}
                      width={35}
                      height={35}
                      style={{ color: "#009688" }}
                    />
                  </Controls.IconButton>
                  <Controls.IconButton
                    onClick={() => {
                      showImage ? deleteImageSelected() : false;
                    }}
                  >
                    <Iconify
                      icon={"fluent:delete-48-filled"}
                      width={35}
                      height={35}
                      style={{ color: "#f44336" }}
                    />
                  </Controls.IconButton>
                </Toolbar>
              </AppBar>
            </Grid>
          </Grid>
          {showImage ? (
            <img
              src={showImage?.imageUrl}
              alt={showImage?.name}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxHeight: "60vh",
                maxWidth: "50vw",
                borderRadius: 15,
                boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
              }}
            />
          ) : (
            <div
              className={classes.bigImage}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Iconify
                icon="streamline:camera-disabled"
                width={200}
                height={200}
                style={{
                  color: "#607d8b",
                  boxShadow: "0 2px 5px 0 rgba(0,0,0,.25)",
                  padding: 25,
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />

      <Dialog
        open={isSaving}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LoadingScreen />
      </Dialog>
    </React.Fragment>
  );
}

export default RecordImages;
