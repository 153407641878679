import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    background: url(/305bg@2x.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const Root = styled.div`
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function NologoLayout({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

export default NologoLayout;
