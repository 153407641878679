import React, { useEffect, useState, useRef } from "react";
import styled, { withTheme } from "styled-components";
import Controls from "./controls";
import { useDispatch, useSelector } from "react-redux";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import moment from "moment";
import { updatePbxInfo } from "../redux/slices/auth";
import { blue, green, red, grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import Api from "../lib/api";
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined";
import Popup from "../components/Popup";
import MicOffIcon from "@material-ui/icons/MicOff";
import CRMUtils from "../utils";
import {
  Card as MuiCard,
  makeStyles,
  Backdrop,
  CircularProgress,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Fade,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";

import Notification from "./Notification";
import PhoneForwardedOutlinedIcon from "@material-ui/icons/PhoneForwardedOutlined";
import PhoneCallbackOutlinedIcon from "@material-ui/icons/PhoneCallbackOutlined";

import ConfirmDialog from "./ConfirmDialog";
import CrmTable from "../components_generic/CrmTableComponentNoNextBack3";
import FaceIcon from "@material-ui/icons/Face";
import { fetchEntitiesService as fetchContactCallsService } from "../redux/slices/contactCallsSlice.js";

import ClickToCallPopup from "./ClickToCallPopup";

var confirmDialogLoading = false;

var dataSliceContactCalls = {
  stateListName: "contactCalls",

  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    limit: 20,
    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },

  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};
const useStyles = makeStyles((theme) => ({
  headerIcon: {
    color: theme.palette.primary.main,
  },
  square: {
    backgroundColor: "transparent !important",
  },
  nameColor: {
    backgroundColor: blue[900],
  },
  UnknownColor: {
    backgroundColor: "#a14b00",
  },
  pageContent: {
    //  margin: theme.spacing(5),
    //  padding: theme.spacing(3),
  },
  Toolbar: {
    justifyContent: "space-between",
  },
  searchInput: {
    width: "50%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  totalValue: {
    position: "absolute",
    zIndex: 10,
    top: "170px",
    left: "225px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PhoneCalls(props) {
  const classes = useStyles();
  const { contact_id, userCanCall, consultantErrorHandle= null } = props;

  const { authUser, pbxConfig } = useSelector((state) => state.auth);
  const fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00");
  const toDate = moment().format("YYYY-MM-DD 23:59:59");
  if (contact_id) {
    dataSliceContactCalls.orgId = contact_id;
  }

  const [recording, setRecording] = useState([]);

  const [openRecordPopup, SetOpenRecordPopup] = useState(false);

  const [openClickToCallMenu, setOpenClickToCallMenu] = useState(false);
  const objRefRowForClickToCall = useRef();

  const closeRecordPopup = async () => {
    await setRecording(null);
    SetOpenRecordPopup(false);
  };

  const getRecording = async (domain, call, id) => {
    const { data } = await Api.getRecording(domain, call);

    setRecording(data);
    SetOpenRecordPopup(true);
  };

  let history = useHistory();
  const dispatch = useDispatch();
  const {
    contactCalls,
    loading,
    errors,
    contactCallsPagination,
    contactCallsLoading,
    contactCallsSaveLoading,
    contactCallsDeleteLoading,
  } = useSelector((state) => state.contactCalls);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const callTypeOptions = () => {
    return [
      { id: "outbound", title: "To Contact/Patient" },
      { id: "inbound", title: "From Contact/Patient" },
    ];
  };

  const callStatusOptions = () => {
    return [
      { id: "answered", title: "Answered" },
      { id: "voicemail", title: "Voicemail" },
      { id: "missed", title: "Missed" },
      { id: "rejected", title: "Rejected" },
    ];
  };

  const userCanGetContactCallRecord = CRMUtils.userHasPermission(authUser, [
    "contact_CallRecord",
  ]);
  const crmTable = useRef();
  let backendServer = process.env.REACT_APP_API_ENDPOINT;
  const filterFields = [
    {
      id: "date_between_from_to",
      type: "date_between_from_to",
      label: "Date Range",
      size: 6,
    },
    {
      id: "type",
      type: "select",
      label: "Call Type",

      options: { items: callTypeOptions(), id: "id", title: "title" },
      size: 2,
    },

    {
      id: "callStatus",
      type: "select",
      label: "Call Status",

      options: { items: callStatusOptions(), id: "id", title: "title" },
      size: 2,
    },
  ];

  /* Common */
  const showMessage = (notifyObj, msg, type = "success") => {
    notifyObj({
      isOpen: true,
      message: msg,
      type: type,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const genericErrorHandle = () => {
    
    if (errors && errors.length > 0) {
      let error = errors[errors.length - 1];
      if (
        error ===
          "*** This contact is already assigned to another consultant***" &&
        typeof consultantErrorHandle === "function"
      ) {
        consultantErrorHandle(error); 
      }

      showMessage(setNotify, error, "error");
    }
  };
  dataSliceContactCalls.errorHandle = genericErrorHandle;
  /* End Common */

  useEffect(() => {
    crmTable.current.refresh(true);
  }, []);

  if (loading)
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  const headCellsMaterial = [
    {
      field: "user",
      title: "Name",
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <Grid container>
              <Grid item xs={2}>
                <Avatar className={classes.nameColor}>
                  {rowData.direction === "outbound" ? (
                    rowData.outboundUserFullName ? (
                      rowData.outboundUserFName.slice(0, 1) +
                      rowData.outboundUserLName.slice(0, 1)
                    ) : (
                      <FaceIcon />
                    )
                  ) : rowData.direction === "inbound" ? (
                    rowData.inboundUserFullName ? (
                      rowData.inboundUserFName.slice(0, 1) +
                      rowData.inboundUserLName.slice(0, 1)
                    ) : (
                      <FaceIcon />
                    )
                  ) : (
                    <FaceIcon />
                  )}
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <Typography>
                  {rowData.direction === "outbound"
                    ? rowData.outboundUserFullName
                      ? rowData.outboundUserFullName
                      : "Unknown"
                    : rowData.direction === "inbound"
                    ? rowData.inboundUserFullName
                      ? rowData.inboundUserFullName
                      : "Unknown"
                    : ""}
                </Typography>

                <Typography>
                  {rowData.direction === "outbound"
                    ? rowData.outboundUserPhone
                      ? rowData.outboundUserPhone
                      : rowData.from
                      ? rowData.from
                      : ""
                    : rowData.direction === "inbound"
                    ? rowData.inboundUserPhone
                      ? rowData.inboundUserPhone
                      : rowData.to
                      ? rowData.to
                      : ""
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      },
    },

    {
      field: "direction",
      title: "Call Type",
      sorting: false,
      render: (rowData) => {
        return rowData.direction === "outbound" ? (
          <Grid container>
            <Grid item xs={2}>
              <Avatar variant="square" className={classes.square}>
                <Tooltip title="Outbound">
                  <PhoneForwardedOutlinedIcon style={{ color: blue[500] }} />
                </Tooltip>
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Typography>To Contact/Patient</Typography>
            </Grid>
          </Grid>
        ) : rowData.direction === "inbound" &&
          rowData.final_state === "voicemail" ? (
          <Grid container>
            <Grid item xs={2}>
              <Avatar variant="square" className={classes.square}>
                <Tooltip title="Voicemail">
                  <VoicemailIcon style={{ color: green[500] }} />
                </Tooltip>
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Typography>From Contact/Patient</Typography>
            </Grid>
          </Grid>
        ) : rowData.direction === "inbound" && !rowData.final_state ? (
          <Grid container>
            <Grid item xs={2}>
              <Avatar variant="square" className={classes.square}>
                <Tooltip title="Missed">
                  <PhoneMissedIcon style={{ color: red[500] }} />
                </Tooltip>
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Typography>From Contact/Patient</Typography>
            </Grid>
          </Grid>
        ) : rowData.direction === "inbound" && rowData.final_state ? (
          <Grid container>
            <Grid item xs={2}>
              <Avatar variant="square" className={classes.square}>
                <Tooltip title="Inbound">
                  <PhoneCallbackOutlinedIcon style={{ color: green[500] }} />
                </Tooltip>
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Typography>From Contact/Patient</Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        );
      },
    },
    {
      field: "created_at",
      title: "Date",
      sorting: false,
      render: (rowData) => {
        return moment(rowData.date).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
          ? "Today, " + moment(rowData.date).format("hh:mm:ss A")
          : moment(rowData.date).format("YYYY-MM-DD") ==
            moment().subtract(1, "days").format("YYYY-MM-DD")
          ? "Yesterday, " + moment(rowData.date).format("hh:mm:ss A")
          : moment(rowData.date).format("YYYY-MM-DD hh:mm:ss A");
      },
    },
  ];

  const makeACall = (event, rowData) => {
    console.log('makeACall',rowData);
    let tmp = {};
    tmp = {
      ...pbxConfig,
      makeACall: true,
      callToPhone:
        rowData.direction === "outbound"
          ? rowData.outboundUserPhone
            ? rowData.outboundUserPhone
            : rowData.from
            ? rowData.from
            : ""
          : rowData.direction === "inbound"
          ? rowData.inboundUserPhone
            ? rowData.inboundUserPhone
            : rowData.to
            ? rowData.to
            : ""
          : "",
      callToName:
        rowData.direction === "outbound"
          ? rowData.outboundUserFullName
            ? rowData.outboundUserFullName
            : "Unknown"
          : rowData.direction === "inbound"
          ? rowData.inboundUserFullName
            ? rowData.inboundUserFullName
            : "Unknown"
          : "",
      callToId: rowData.direction && rowData.direction === "inbound"
          ?rowData.from_id
          :rowData.to_id
    };

    dispatch(updatePbxInfo(tmp));
    console.log("makeACall: true PhoneCalls");
  };

  
  const clickToCallExternal = async (destination) => {
    const { data } = await Api.originateCallTo(
      authUser.pbx_domain,
      authUser.ext,
      authUser.phone,
      destination
    );
  };

  const runClickToCall2 = (phone, name, id = null) => {
    if (authUser.click_to_call == "web_phone") {
      makeACallMenu(phone, name, id);
    } else if (authUser.click_to_call == "external_phone") {
      clickToCallExternal(phone);
    }
  };

  const makeACallMenu = (phone, name, id = null) => {
    let tmp = {};

    tmp = {
      ...pbxConfig,
      makeACall: true,
    };

    //if (pbxConfig.callToPhone || pbxConfig.callToName) {
    tmp.callToPhone = phone;
    tmp.callToName = name;
    tmp.callToId = (id?id:null);
    //}

    dispatch(updatePbxInfo(tmp));
    // console.log("makeACall: true userSummaryreport");
  };

  const handleRoute = (rowData) => {
    if (rowData.direction === "outbound") {
      if (rowData.outboundUserPhone) {
        history.push(`/messagessms/${rowData.outboundUserPhone}`);
      } else if (rowData.from) {
        history.push(`/messagessms/${rowData.from}`);
      }
    } else if (rowData.direction === "inbound") {
      if (rowData.inboundUserPhone) {
        history.push(`/messagessms/${rowData.inboundUserPhone}`);
      } else if (rowData.to) {
        history.push(`/messagessms/${rowData.to}`);
      }
    }

    //  history.push(`/contacts/${contact_id}`);
  };

  const extraActions = [
    {
      icon: () => (
        <Controls.IconButton
          style={{ color: userCanCall ? blue[500] : grey[500] }}
        >
          <PhoneOutlinedIcon fontSize="small" />
        </Controls.IconButton>
      ),
      disabled: !userCanCall,
      tooltip: "Call Back",
      onClick: (event, rowData) => {
        //makeACall(event, rowData);
        console.log("rowData", rowData);
        if (authUser.click_to_call_external) {
          objRefRowForClickToCall.current = rowData;

          runClickToCall2(
            objRefRowForClickToCall.current.direction === "inbound"
              ? objRefRowForClickToCall.current.from
              : objRefRowForClickToCall.current.to,
            objRefRowForClickToCall.current.direction === "inbound"
              ? objRefRowForClickToCall.current.from
              : objRefRowForClickToCall.current.to,
            objRefRowForClickToCall.current.direction === "inbound"
              ?objRefRowForClickToCall.current.from_id
              :objRefRowForClickToCall.current.to_id
          );
          // setOpenClickToCallMenu(true);
        } else makeACall(event, rowData);
      },
    },
  ];

  if (userCanGetContactCallRecord) {
    extraActions.push({
      icon: () => (
        <Controls.IconButton style={{ color: blue[500] }}>
          <RecordVoiceOverOutlinedIcon fontSize="small" />
        </Controls.IconButton>
      ),
      tooltip: "Get Call Record",
      onClick: (event, rowData) => {
        getRecording(rowData.pbx_domain, rowData.pbx_recording, rowData.id);
      },
    });
  }

  const rowColorAlternateArray = [{ color: green[50] }, { color: grey[50] }];
  const pageSizeOptions = [10, 15, 20, 30];

  return (
    <>
      <div style={{ display: "flex", position: "relative" }}>
        {
          <Typography variant="h6" className={classes.totalValue}>
            Total: {contactCallsPagination ? contactCallsPagination.total : ""}
          </Typography>
        }
      </div>
      <CrmTable
        ref={crmTable}
        dpageSize={10}
        sspageContent={true}
        dpageSizeOptions={pageSizeOptions}
        title={"Contact Calls"}
        columns={headCellsMaterial}
        data={contactCalls}
        paginator={contactCallsPagination}
        sliceData={dataSliceContactCalls}
        sliceGet={fetchContactCallsService}
        customDateFilter={"consultant_sales_date_between_from_to"}
        // fromDate={fromDate}
        toDate={toDate}
        exportButton={false}
        isLoading={contactCallsLoading}
        chipsArrayNshow={true}
        search={false}
        extraActions={extraActions}
        filterFormData={{
          filterFields: filterFields,
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />

      <Popup
        title="Recording"
        openPopup={openRecordPopup}
        onClose={closeRecordPopup}
      >
        {recording && recording !== "NO_VALID_FILE" && (
          <audio controls autoplay name="media">
            <source
              src={
                backendServer
                  .replace("com:8000/api", "com:8000")
                  .replace("com/api", "com") + recording
              }
              type={"audio/mpeg"}
            ></source>
          </audio>
        )}
        {recording && recording === "NO_VALID_FILE" && (
          <p>
            <MicOffIcon /> No Record available or Permissions!
          </p>
        )}
      </Popup>

      {openClickToCallMenu && (
        <ClickToCallPopup
          domain={authUser.pbx_domain}
          sourceExt={authUser.ext}
          originationNum={authUser.phone}
          destination={
            objRefRowForClickToCall.current.direction === "inbound"
              ? objRefRowForClickToCall.current.from
              : objRefRowForClickToCall.current.to
          }
          makeACall={makeACallMenu}
          destinationName={
            objRefRowForClickToCall.current.direction === "inbound"
              ? objRefRowForClickToCall.current.from
              : objRefRowForClickToCall.current.to
          }
          setOpenClickToCallMenu={setOpenClickToCallMenu}
        />
      )}
    </>
  );
}

export default PhoneCalls;
