import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import PieChartIcon from "@material-ui/icons/PieChart";
import AssignmentIcon from "@material-ui/icons/Assignment";

import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

import TuneIcon from "@material-ui/icons/Tune";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DialpadIcon from "@material-ui/icons/Dialpad";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import {
  AccountBalance,
  AssignmentTurnedInOutlined,
  Payment as PaymentIcon,
} from "@material-ui/icons";
import ContactsIcon from "@material-ui/icons/Contacts";
import ForumIcon from "@material-ui/icons/Forum";
import GroupIcon from "@material-ui/icons/Group";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TodayIcon from "@material-ui/icons/Today";
import Iconify from "../components/Iconify";
// Components-Elements import

const SignIn = React.lazy(() => import("../pages/auth/SignIn"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const Page404 = React.lazy(() => import("../pages/auth/Page404"));
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Dashboard2 = React.lazy(() => import("../pages/dashboard/index2"));
const Dashboard2a = React.lazy(() => import("../pages/dashboard/index3"));
const Dashboardnew = React.lazy(() => import("../pages/dashboard/index4"));
const Dashboardnew2 = React.lazy(() => import("../pages/dashboard/index5"));
const DashboardRegular = React.lazy(() =>
  import("../pages/dashboard/indexDashboard")
);

const Profile = React.lazy(() => import("../pages/profile"));
const Users2 = React.lazy(() => import("../pages/users/index2"));
const UsersDoctors = React.lazy(() => import("../pages/users/indexDoctors"));
const UsersDirectory = React.lazy(() =>
  import("../pages/users/indexDirectory2")
);
const ContactMetadataReport = React.lazy(() =>
  import("../pages/contactMetadataReport")
);
const QuoteDiscountReport = React.lazy(() =>
  import("../pages/quoteDiscountReport")
);
const InvoiceDiscountReport = React.lazy(() =>
  import("../pages/invoiceDiscountReport")
);

const DoctorsRemarks = React.lazy(() => import("../pages/doctorsRemarks"));
const ProductsList = React.lazy(() => import("../pages/productslist"));

const Roles = React.lazy(() => import("../pages/roles"));
const Departments = React.lazy(() => import("../pages/departments/index2"));
const CustomFields = React.lazy(() => import("../pages/customFields"));
const Settings = React.lazy(() => import("../pages/settings"));
const Records2 = React.lazy(() => import("../pages/records/index2"));
const RecordsCoordination2 = React.lazy(() =>
  import("../pages/records/index2")
);
const RecordsCoordinationContactMark = React.lazy(() =>
  import("../pages/records/indexContactMark")
);
const RecordTransfersMark = React.lazy(() =>
  import("../pages/RecordTransfersMark")
);

const Records3 = React.lazy(() => import("../pages/records/index_old"));
const Records = React.lazy(() => import("../pages/records"));
const Quotes = React.lazy(() => import("../pages/quotes"));
const Products3 = React.lazy(() => import("../pages/products/index3"));
const Categories2 = React.lazy(() => import("../pages/categories/index2"));
const CommunicationGroups = React.lazy(() =>
  import("../pages/communicationGroups")
);
const PaymentGateways = React.lazy(() => import("../pages/paymentGateways"));
const DocumentTypes = React.lazy(() => import("../pages/documentTypes/index2"));
const Contacts = React.lazy(() => import("../pages/contacts"));
const UnsuscribeContact = React.lazy(() =>
  import("../pages/contacts/unsuscribeContact")
);
const AppointmentConfirmation = React.lazy(() =>
  import("../pages/appointmentsnew/appointmentConfirmation")
);

const ContactView = React.lazy(() => import("../pages/contacts/view"));
const ContactSourcesReport = React.lazy(() =>
  import("../pages/contacts/sourcesReport")
);
const userPbxSummaryReport = React.lazy(() =>
  import("../pages/pbx/userSummaryReport")
);
const callCenterPbxSummaryReport = React.lazy(() =>
  import("../pages/pbx/userSummaryReport")
);
const userSalesPbxSummaryReport = React.lazy(() =>
  import("../pages/pbx/userSummaryReport")
);
const Organizations = React.lazy(() => import("../pages/organizations"));
const Invoices = React.lazy(() => import("../pages/invoices"));
const Invoicesnew = React.lazy(() => import("../pages/invoices/indexnew"));
const InvoicesPage2 = React.lazy(() => import("../pages/invoices/indexnew2"));
const FraudolentContacts = React.lazy(() =>
  import("../pages/fraudolentcontacts/index")
);
const Invoices2 = React.lazy(() => import("../pages/invoices/index2"));
const Tasks = React.lazy(() => import("../pages/tasks"));
const PortalIndex = React.lazy(() => import("../modules/portalModule/pages"));
const PortalIndex2 = React.lazy(() =>
  import("../modules/portalModule/pages/index2")
);
const checkIn = React.lazy(() =>
  import("../pages/appointmentsnewcheckin/checkin")
);
const ContactInformation = React.lazy(() =>
  import("../pages/appointmentsnewcheckin/contactInformation")
);
const ContactAppointmentsForToday = React.lazy(() =>
  import("../pages/appointmentsnewcheckin/appointmentsfortoday")
);
const PortalAuth = React.lazy(() =>
  import("../modules/portalModule/pages/auth")
);
const PortalForgetPass = React.lazy(() =>
  import("../modules/portalModule/pages/forgetPage")
);
const PortalChangePsw = React.lazy(() =>
  import("../modules/portalModule/pages/changePswPage")
);
const PayrollAssistantsReports = React.lazy(() =>
  import("../pages/payrollAssistantsReports/index2")
);
const PayrollAnesthesiologistsReports = React.lazy(() =>
  import("../pages/payrollAnesthesiologistsReports")
);
const PaymentsActivityReports = React.lazy(() =>
  import("../pages/invoicePaymentsActivityReport")
);
const PaymentsActivityReportsNew = React.lazy(() =>
  import("../pages/invoicePaymentsActivityReport/index2")
);
const ContractsReportByConsultantBuilding = React.lazy(() =>
  import("../pages/contractsReport/index4")
);
const PayrollReports2 = React.lazy(() =>
  import("../pages/payrollreports/index2")
);
const PayrollReports3 = React.lazy(() =>
  import("../pages/payrollreports/index3")
);
const PayrollGroupedReports = React.lazy(() =>
  import("../pages/payrollreports/indexGrouped")
);

const FinancedPatients = React.lazy(() => import("../pages/financedPatients")); // only for Miami Life

const FinancingContact = React.lazy(() => import("../pages/financingContact"));

const ContractsReport2 = React.lazy(() =>
  import("../pages/contractsReport/index5")
);
const ContractsReport2New = React.lazy(() =>
  import("../pages/contractsReport/index5New")
);
const RecordProductCosts = React.lazy(() =>
  import("../pages/recordProductCosts")
);
const MyQuotesReport2 = React.lazy(() =>
  import("../pages/quotesreports/index3")
);
const MyQuotesReport3 = React.lazy(() =>
  import("../pages/quotesreports/index4")
);

const MyQuotesReport2New = React.lazy(() =>
  import("../pages/quotesreports/index3New")
);
const PaymentReportsList = React.lazy(() =>
  import("../pages/paymentReports/indexList")
);
const PaymentReportsNewByInvoices = React.lazy(() =>
  import("../pages/paymentReports/indexHistoryNewByInvoices")
);
const PaymentReports = React.lazy(() =>
  import("../pages/paymentReports/index2")
);
const PaymentReportsDev = React.lazy(() => import("../pages/paymentReports"));
const PaymentReportsCash = React.lazy(() =>
  import("../pages/paymentReports/indexCash")
);
const PaymentReportsHistory = React.lazy(() =>
  import("../pages/paymentReports/indexHistory")
);
const AdminTools = React.lazy(() => import("../pages/adminTools"));
const DuplicateContactsReport = React.lazy(() =>
  import("../pages/duplicateContactsReport")
);
const SalesDuplicateContactsReport = React.lazy(() =>
  import("../pages/duplicateContactsReport")
);
const Contracts = React.lazy(() => import("../pages/contracts"));
const InfluencerContracts = React.lazy(() =>
  import("../pages/influencercontracts/index")
);

const recordProductTemplates = React.lazy(() =>
  import("../pages/recordProductTemplates/index2")
);
const doctorRemarksTemplate = React.lazy(() =>
  import("../pages/doctorRemarksTemplate")
);
const contactFormTemplates = React.lazy(() =>
  import("../pages/contactFormTemplates")
);
const contactFormTemplatesCategories = React.lazy(() =>
  import("../pages/contactFormTemplates/indexCategory")
);
const ProcedureTimeline2 = React.lazy(() =>
  import("../pages/procedureTimeline/index2")
);
const ProcedureTimeline4 = React.lazy(() =>
  import("../pages/procedureTimeline/index4")
);
const ProcedureTimeline5 = React.lazy(() =>
  import("../pages/procedureTimeline/index5")
);
const ProcedureTimelineCoordination = React.lazy(() =>
  import("../pages/procedureTimeline/index5")
);
const ProcedureTimelineSocialMedia = React.lazy(() =>
  import("../pages/procedureTimeline/index5")
);
const ProcedureTimeline6 = React.lazy(() =>
  import("../pages/procedureTimeline/index6")
);
const ContactDistribution = React.lazy(() =>
  import("../pages/contactDistribution")
);
const Appointments = React.lazy(() => import("../pages/appointments/index2"));
const RecordsToRemarketing = React.lazy(() =>
  import("../pages/remarketing/recordsToRemarketing")
);
const ContactsForCampaigns = React.lazy(() =>
  import("../pages/campaigns/contacts")
);
const CampaignsTemplates = React.lazy(() =>
  import("../pages/campaigns/templates")
);

const CampaignsDesignTabs = React.lazy(() =>
  import("../pages/campaigns/design")
);

const Campaigns = React.lazy(() => import("../pages/campaigns/index"));
const AssignedCampaigns = React.lazy(() =>
  import("../pages/campaigns/assignedCampaigns")
);
const AssignedCampaignsDashboard = React.lazy(() =>
  import("../pages/campaigns/assignedCampaignsDashboard")
);

const CampaignsDashboard = React.lazy(() =>
  import("../pages/campaigns/dashboard")
);

const AssignedCampaignsPriorities = React.lazy(() =>
  import("../pages/campaigns/assignedCampaignsPriorities")
);
const RemarketingDashboard = React.lazy(() =>
  import("../pages/remarketing/remarketingDashboard")
);
const RemarketingReport = React.lazy(() =>
  import("../pages/remarketing/campaignremarketingreport")
);
const Contacts2 = React.lazy(() => import("../pages/contacts/index2"));
const Contacts3 = React.lazy(() => import("../pages/contacts/index5"));
const Contacts4 = React.lazy(() => import("../pages/contacts/index4"));
const Contacts3Coordinators = React.lazy(() =>
  import("../pages/contacts/index5")
);
const CallCenterCoordinatorsCRM = React.lazy(() =>
  import("../pages/contacts/indexSearch")
);
const ContactSearchCRM = React.lazy(() =>
  import("../pages/contacts/indexSearch")
);

const ContactsP = React.lazy(() => import("../pages/contacts/indexP"));

const BillingManagerContacts3 = React.lazy(() =>
  import("../pages/contacts/index5")
);
const ClinicFlow = React.lazy(() => import("../pages/clinicFlow"));
const Contacts5 = React.lazy(() => import("../pages/contacts/index3"));
const ExpenseCategory = React.lazy(() =>
  import("../modules/accountingModule/pages/expenseCategoryPage")
);
const ExpenseItem = React.lazy(() =>
  import("../modules/accountingModule/pages/expenseItemPage")
);
const Expense = React.lazy(() =>
  import("../modules/accountingModule/pages/expensePage")
);
const CashBox = React.lazy(() =>
  import("../modules/accountingModule/pages/cashBoxPage")
);
const CashFlow = React.lazy(() =>
  import("../modules/accountingModule/pages/cashFlowPage")
);
const Groups = React.lazy(() => import("../pages/groups/index2"));
const ConsultantsSalesReport = React.lazy(() =>
  import("../pages/consultantsSalesReport")
);
const SurgeonQuotesRecordProductReports = React.lazy(() =>
  import("../pages/surgeonQuotesRecordProductReports")
);
const CancellationMotives = React.lazy(() =>
  import("../pages/cancellationMotives/index2")
);
const AppointmentsMotives = React.lazy(() =>
  import("../pages/appointmentsMotives/index2")
);
const ContactMetadataCategories = React.lazy(() =>
  import("../pages/contactMetadataCategories")
);

const UserChangeRolesReasons = React.lazy(() =>
  import("../pages/userChangeRolesReasons/index")
);

const UserChangeRolesReport = React.lazy(() =>
  import("../pages/userChangeRolesReport/index")
);
const InvoicesForfeitureReport = React.lazy(() =>
  import("../pages/invoicesForfeiture")
);

const ProductGiftReason = React.lazy(() =>
  import("../pages/productGiftReason")
);
const ProductGiftReport = React.lazy(() =>
  import("../pages/productGiftReport")
);
const CreditReport = React.lazy(() => import("../pages/CreditsReport/index2"));
const RefundRequests2 = React.lazy(() =>
  import("../pages/refundRequests/index2A")
);
const CoordinationRefundRequests2 = React.lazy(() =>
  import("../pages/refundRequests/index2A")
);
const RecordProductReports2 = React.lazy(() =>
  import("../pages/recordProductReports/index5")
);
const RecordProductReports6 = React.lazy(() =>
  import("../pages/recordProductReports/index6newWithSelection")
);
const ProcedureReportDashboard = React.lazy(() =>
  import("../pages/procedureReportDashboard/index")
);

const BillingProductReports6 = React.lazy(() =>
  import("../pages/recordProductReports/index6newWithSelection")
);
const QuotesReport2 = React.lazy(() =>
  import("../pages/quotesreports/index2A")
);
const InvoicesReport2 = React.lazy(() =>
  import("../pages/invoicesreports/index2")
);

const InvoicesToApplyDiscountReport = React.lazy(() =>
  import("../pages/invoicesreports/indextoapplydiscounta")
);

const InvoicesPayment = React.lazy(() =>
  import("../pages/invoicesreports/indexpayment")
);
const PendingToRescheduleReports = React.lazy(() =>
  import("../pages/pendingToRescheduleReports/index2")
);
const PendingToRescheduleReportsCoordination = React.lazy(() =>
  import("../pages/pendingToRescheduleReports/index3")
);
const RefundChargeBackReport = React.lazy(() =>
  import("../pages/refundChargeBackReport/index")
);
const ChargeBackReport = React.lazy(() => import("../pages/chargeBackReport"));
const RefundsChargeBackRisk = React.lazy(() =>
  import("../pages/refundChargeBackRiskReport/index")
);
const PendingToScheduleReportsCoordination = React.lazy(() =>
  import("../pages/pendingToScheduleReports/index")
);
const NoDoctorReportsCoordination = React.lazy(() =>
  import("../pages/procedurenoDoctorsReports/index")
);
const RecordProductTransferReports = React.lazy(() =>
  import("../pages/recordProductTransferReports/index2")
);
const RecordProductTransferReportsCoordination = React.lazy(() =>
  import("../pages/recordProductTransferReports/indexCoordination")
);
const CoordinationRecordProductTransferReports = React.lazy(() =>
  import("../pages/recordProductTransferReports/index2")
);

const PendingToRescheduleReports2 = React.lazy(() =>
  import("../pages/pendingToRescheduleReports/index2")
);
const ProcedureCanceledReportsCoordination = React.lazy(() =>
  import("../pages/procedureCanceledReports/index2")
);
const ProcedureRefundReportsCoordination = React.lazy(() =>
  import("../pages/procedureRefundReports/index2")
);
const TemplatesCategories = React.lazy(() =>
  import("../pages/templatesCategories/index2")
);
const CommissionRules = React.lazy(() =>
  import("../modules/adminModule/pages/admCommissionRulePage")
);
const PollRouter = React.lazy(() =>
  import("../modules/pollModule/pages/pollRouter")
);
const pollReport = React.lazy(() =>
  import("../modules/pollModule/pages/pollReport")
);
const pollPuntuationReport = React.lazy(() =>
  import("../modules/pollModule/pages/pollPuntuationReport")
);
const StatesRouter = React.lazy(() =>
  import("../modules/statesModule/pages/StatesRouter")
);
const CommissionRulesReport = React.lazy(() =>
  import("../modules/adminModule/pages/admCommissionRuleReportPage")
);
const CommissionReports = React.lazy(() =>
  import("../pages/commissionReports")
);
const AdmFaq = React.lazy(() =>
  import("../modules/adminModule/pages/admFaqPage")
);
const TicketsResponsibleReports = React.lazy(() =>
  import("../pages/TicketReports/index")
);
const TicketUsersReports = React.lazy(() =>
  import("../pages/TicketReports/index2")
);
const UserCallHistory = React.lazy(() =>
  import("../pages/userCallHistory/index2.js")
);
const CallOrgHistory = React.lazy(() =>
  import("../pages/callHistoryOrg/index2")
);
const InternalChatReport = React.lazy(() =>
  import("../pages/internalchat/report")
);
const BlockedChatUsersReport = React.lazy(() =>
  import("../pages/internalchat/blockedUsersReport.js")
);
const InternalChatPage = React.lazy(() =>
  import("../pages/internalchat/index")
);
const UserUnreadMissedCalls = React.lazy(() =>
  import("../pages/userCallHistory/index3")
);
const ConsultantsList = React.lazy(() => import("../pages/usersNew/index"));
const ConsultantsListSales = React.lazy(() =>
  import("../pages/usersNew/index")
);
const DoctorList = React.lazy(() => import("../pages/doctorsNew/index"));
const CoordinatorsList = React.lazy(() =>
  import("../pages/coordinatorsList/index")
);
const CallCenterAgentList = React.lazy(() =>
  import("../pages/agentList/index")
);
const FrontDeskAgentList = React.lazy(() => import("../pages/agentList/index"));
const SocialMediaAgentList = React.lazy(() =>
  import("../pages/agentList/index")
);
const InvCategory = React.lazy(() =>
  import("../modules/inventoryModule/pages/invCategoryPage")
);
const InvProduct = React.lazy(() =>
  import("../modules/inventoryModule/pages/invProductPage")
);
const InvMeasure = React.lazy(() =>
  import("../modules/inventoryModule/pages/invMeasurePage")
);
const InvLocalization = React.lazy(() =>
  import("../modules/inventoryModule/pages/invLocalizationPage")
);
const InvWarehouseType = React.lazy(() =>
  import("../modules/inventoryModule/pages/invWarehouseTypePage")
);
const InvWarehouse = React.lazy(() =>
  import("../modules/inventoryModule/pages/invWarehousePage")
);
const InvProvider = React.lazy(() =>
  import("../modules/inventoryModule/pages/invProviderPage")
);
const InvWhMovement = React.lazy(() =>
  import("../modules/inventoryModule/pages/invWhMovementPage")
);
const InvWhMovementDetail = React.lazy(() =>
  import("../modules/inventoryModule/pages/invWhMovementDetailPage")
);
const InvWhProduct = React.lazy(() =>
  import("../modules/inventoryModule/pages/invWhProductPage")
);
const InvWhUserPermission = React.lazy(() =>
  import("../modules/inventoryModule/pages/InvWhUserPermissionPage")
);
const InvDashboard = React.lazy(() =>
  import("../modules/inventoryModule/pages/invDashboardPage")
);
const SalesReports = React.lazy(() => import("../pages/salesReports"));
const Roundrobin = React.lazy(() => import("../pages/roundrobin"));
const TopSellers = React.lazy(() => import("../pages/topSellers"));

const UnansweredSMSMMS = React.lazy(() =>
  import("../pages/unansweredsmsmmsbyuser/index.js")
);

//const SMSMMSTemplatesCategories = React.lazy(() => import("../pages/smsmmsTemplatesCategories/index"));
const SMSMMSTemplates = React.lazy(() => import("../pages/smsmmstemplates"));
const AppointmentsTodayList = React.lazy(() =>
  import("../pages/appointmentsnewcheckin/appointmentsTodayList2")
);

const TopBottomSellers = React.lazy(() => import("../pages/topBottomSellers"));
const HolidaysConfiguration = React.lazy(() =>
  import("../pages/holidaysconfiguration/index")
);
const ClientsList = React.lazy(() => import("../pages/clientsList"));
const DeactivateHistory = React.lazy(() =>
  import("../pages/deactivateHistory")
);
const SMSMMSCampaignReport = React.lazy(() =>
  import("../pages/smsmmsCampaignReport/CampaignDashboard")
);
const CampaignCredits = React.lazy(() =>
  import("../pages/campaignCredits/index")
);
const AppointmentTypes = React.lazy(() =>
  import("../pages/appointmentsnewtypes/appointmentsTypes")
);

const AppointmentNewDashboard = React.lazy(() =>
  import("../pages/appointmentsnewdahsboard/appointmentsDashboard")
);

const PendingAppointmentsReport = React.lazy(() =>
  import("../pages/appointmentsnewreports/pendingAppointmentsReport")
);

const DailyMassageRecord = React.lazy(() =>
  import("../pages/appointmentsnewreports/appointmentsMassageRecord")
);

const AppointmentsCancelationReason = React.lazy(() =>
  import("../pages/appointmentsnewcancelationreason/index")
);

const AppointmentsMovementsReason = React.lazy(() =>
  import("../pages/appointmentsnewmovementreason/index")
);

const AppointmentNew = React.lazy(() =>
  import("../pages/appointmentsnew/index2")
);

const AppointmentsReport = React.lazy(() =>
  import("../pages/appointmentsnewreports/appointmentReport2")
);

const DeactivateReasons = React.lazy(() =>
  import("../pages/DeactivateReasons")
);
const CommentCategories = React.lazy(() =>
  import("../pages/commentCategories/index2")
);

const TopSellersOrg = React.lazy(() =>
  import("../pages/topSellers/indexOrganization")
);
const TopSellersOrgByMoney = React.lazy(() =>
  import("../pages/topSellers/indexOrganizationByMoney")
);
const TopSellersOrgByCalls = React.lazy(() =>
  import("../pages/topSellers/indexOrganizationByCalls")
);
const PaymentReports2 = React.lazy(() =>
  import("../pages/paymentReports/index2")
);
const CalendarHeatmap = React.lazy(() =>
  import("../pages/calendarHeatmap/index")
);
const ProcedureHeatmap = React.lazy(() =>
  import("../pages/procedureHeatmap/index")
);
const UnassignedContacts2 = React.lazy(() =>
  import("../pages/unassignedContacts/index2")
);
const MyContractReport = React.lazy(() =>
  import("../pages/contractsReport/myContractReport")
);
const MyContractReport2 = React.lazy(() =>
  import("../pages/contractsReport/index2")
);
const DoctorHeatMap = React.lazy(() => import("../pages/doctorHeatmap"));
const DoctorHeatMapNewCoordination = React.lazy(() =>
  import("../pages/doctorHeatmap")
);
const Buildings = React.lazy(() => import("../pages/buildings/index3"));
const Rooms = React.lazy(() => import("../pages/rooms"));
const RoomServices = React.lazy(() => import("../pages/roomServices"));
const RoomStates = React.lazy(() => import("../pages/roomStates"));
const Financing = React.lazy(() => import("../pages/financing"));

const TicketCategories = React.lazy(() =>
  import("../pages/ticketCategory/index2")
);
const TicketPriority = React.lazy(() => import("../pages/ticketPriority"));
const Tikets = React.lazy(() => import("../pages/tickets/index"));
const TiketsNew = React.lazy(() => import("../pages/tickets/indexNew"));
const MyTikets = React.lazy(() => import("../pages/tickets/index2"));
const TicketsAgent = React.lazy(() => import("../pages/tickets/ticketsAgent"));
const ContactsMassagesControlReport = React.lazy(() =>
  import("../pages/contactsMassagesControlReport")
);
const Chat = React.lazy(() => import("../pages/chat"));
const ToDoCalendar = React.lazy(() => import("../pages/contacts/toDoCalendar"));
const ToDoCalendarCoordination = React.lazy(() =>
  import("../pages/contacts/toDoCalendar")
);
const ToDoCalendarCoordination2 = React.lazy(() =>
  import("../pages/contacts/toDoCalendar")
);

const ToDoCalendarCoordinationNew = React.lazy(() =>
  import("../pages/contacts/toDoCalendarNew")
);
const RecordSearch2 = React.lazy(() => import("../pages/records/search2"));
const RecordSearchSales = React.lazy(() => import("../pages/records/search2"));
const RecordSearchCallCenter = React.lazy(() =>
  import("../pages/records/search2")
);
const TopDoctors = React.lazy(() => import("../pages/topDoctors"));
const RefundReports = React.lazy(() => import("../pages/refundReports"));
const TopClosingSpeed = React.lazy(() => import("../pages/topClosingSpeed"));
const TopSources = React.lazy(() => import("../pages/topSources"));
const Messages = React.lazy(() =>
  import("../modules/messagesModule/pages/index")
);
const MessagesSMS = React.lazy(() => import("../pages/messagesSMS/index"));
const MessagesCenter = React.lazy(() => import("../pages/messagesSMS/index2"));

const Sources = React.lazy(() => import("../pages/sources"));

const Tags = React.lazy(() => import("../pages/tags"));
const SalesReportsNew = React.lazy(() =>
  import("../pages/salesReportsNew/index")
);
const SalesReportsNew2 = React.lazy(() =>
  import("../pages/salesReportsNew/index2")
);
const SalesReportsNewWithDoctor = React.lazy(() =>
  import("../pages/salesReportsNew/indexWithDoctors")
);
const ContactsProductsNoProcedure = React.lazy(() =>
  import("../pages/contactsProductsNoProcedure/index2")
);
const ContactsProductsNoProcedureNew = React.lazy(() =>
  import("../pages/contactsProductsNoProcedure/index2")
);
const TicketsReport = React.lazy(() => import("../pages/ticketsReport/index"));
const SalesTicketsReport = React.lazy(() =>
  import("../pages/ticketsReport/index")
);
const CoordinationTicketsReport = React.lazy(() =>
  import("../pages/ticketsReport/index")
);
const SMSReport = React.lazy(() => import("../pages/smsReport/index"));
const CallsReport = React.lazy(() => import("../pages/callsReport/index"));
const SalesBySourceReport = React.lazy(() =>
  import("../pages/salesBySource/index")
);
const RecordsPA = React.lazy(() =>
  import("../pages/records/indexPersonalAssistant")
);
const RecordProductReportsPA = React.lazy(() =>
  import("../pages/recordProductReports/indexPA")
);
const ContactsBySource = React.lazy(() =>
  import("../pages/contactsBySource/index2")
);
const UsersLogins = React.lazy(() => import("../pages/usersLogins/index2"));
const WhatsAppConfiguration = React.lazy(() =>
  import("../pages/whatsAppConfiguration")
);
const WhatsAppApplication = React.lazy(() =>
  import("../pages/whatsAppApplication")
);
const WhatsAppGroup = React.lazy(() => import("../pages/whatsAppGroup"));

const SalesMenuContact = React.lazy(() => import("../pages/contacts/index5"));
const SalesMyContractReport = React.lazy(() =>
  import("../pages/contractsReport/myContractReport")
);
const SalesDoctorHeatMap = React.lazy(() => import("../pages/doctorHeatmap"));
const SalesRoundrobin = React.lazy(() => import("../pages/roundrobin"));
const SalesMyQuotesReport = React.lazy(() =>
  import("../pages/quotesreports/index4")
);
const SalesContractsReport2 = React.lazy(() =>
  import("../pages/contractsReport/index5New")
);
const BillingContractsReport2 = React.lazy(() =>
  import("../pages/contractsReport/index5New")
);
const CoordinationContractsReport2 = React.lazy(() =>
  import("../pages/contractsReport/index5New")
);
const SalesQuotesReport2 = React.lazy(() =>
  import("../pages/quotesreports/index2A")
);
const BillingQuotesReport2 = React.lazy(() =>
  import("../pages/quotesreports/index2A")
);

const SalesInvoicesReport2 = React.lazy(() =>
  import("../pages/invoicesreports/index2")
);

const BillingInvoicesReport2 = React.lazy(() =>
  import("../pages/invoicesreports/index2")
);
const SalesInvoicesPayment = React.lazy(() =>
  import("../pages/invoicesreports/indexpayment")
);
const CoordinationInvoicesPayment = React.lazy(() =>
  import("../pages/invoicesreports/indexpayment")
);
const SalesMyQuotesReport2 = React.lazy(() =>
  import("../pages/quotesreports/index3New")
);
const BillingMyQuotesReport2 = React.lazy(() =>
  import("../pages/quotesreports/index3New")
);
const SalesConsultantsSalesReport = React.lazy(() =>
  import("../pages/consultantsSalesReport")
);
const SalesTopSellersOrg = React.lazy(() =>
  import("../pages/topSellers/indexOrganization")
);
const SalesTopSellers = React.lazy(() => import("../pages/topSellers"));
const SalesTopDoctors = React.lazy(() => import("../pages/topDoctors"));
const PrivacyPage = React.lazy(() => import("../pages/privacyPage"));
const ClaimPage = React.lazy(() => import("../pages/claimPage"));
const SpecialPage = React.lazy(() => import("../pages/specialPage"));

const BadContactReport = React.lazy(() => import("../pages/badContactReport"));

const FirstDepositReport = React.lazy(() =>
  import("../pages/firstDepositReport")
);

const ContactsPatientsStateZipCode = React.lazy(() =>
  import("../pages/contactsPatientsStateZipCode")
);

const AccountingSalesReport = React.lazy(() =>
  import("../pages/accountingSalesReport/tabs")
);

const AccountingSalesReportNew = React.lazy(() =>
  import("../pages/accountingSalesReportNew")
);

const FraudReasons = React.lazy(() =>
  import("../pages/fraudolentcontactsreasons/index")
);

const AccountingSalesReport3 = React.lazy(() =>
  import("../pages/accountingSalesReport3")
);

const ProductsNoProcedurePayroll = React.lazy(() =>
  import("../pages/productsnoprocedurepayroll")
);

const ComparativeSalesAnalysis = React.lazy(() =>
  import("../pages/comparativeSalesAnalysis")
);

const RecordsDeposit = React.lazy(() => import("../pages/recordsDeposit"));

const CreditMemoReasons = React.lazy(() =>
  import("../pages/creditMemoReasons")
);

const ReviewInvoice = React.lazy(() =>
  import("../pages/merchant/reviewInvoice")
);

const PayInvoice = React.lazy(() =>
  import("../components/forms/PayInvoiceForm")
);

// const SalesmanPendingPayroll = React.lazy(() =>
//   import("../pages/salesmanPendingPayroll")
// );

// const PayrollSales = React.lazy(() => import("../pages/payrollSales"));
const PayrollConfigurationsTabs = React.lazy(() =>
  import("../pages/payrollConfigurations")
);

const ProductsServicesReport = React.lazy(() =>
  import("../pages/ProductsServicesReport/index2")
);
const ProductsServicesReport2 = React.lazy(() =>
  import("../pages/ProductsServicesReport/index2")
);

const ContactActivityReport = React.lazy(() =>
  import("../pages/ContactActivityReport")
);

const PaymentReconciliationReport = React.lazy(() =>
  import("../pages/paymentReconciliationReport")
);
const ManualTransactionReconciliation = React.lazy(() =>
  import("../pages/manualtransactionreconciliation")
);
const CommissionPayrollReportTabs = React.lazy(() =>
  import("../pages/commissionPayrollReport")
);

const SalesManagerPayrollReportTabs = React.lazy(() =>
  import("../pages/salesManagerPayrollReport")
);

const CoordinationPayrollReportTabs = React.lazy(() =>
  import("../pages/coordinationPayrollReport")
);

const CoordinationManagerPayrollReportTabs = React.lazy(() =>
  import("../pages/coordinationManagerPayrollReport")
);

const SalesmanPayrollReportTabs = React.lazy(() =>
  import("../pages/salesmanPendingPayroll/tabs")
);

const ProcedureAnalyticsReport = React.lazy(() =>
  import("../pages/procedureAnalyticsReport")
);
const AnalyticsReport = React.lazy(() => import("../pages/analyticsReportNew"));

const WhiteBlackRules = React.lazy(() => import("../pages/whiteBlackRules"));

const InvoicesLogs = React.lazy(() => import("../pages/invoicesLogs"));

const RecordProductDateTransferReport = React.lazy(() =>
  import("../pages/recordProductDateTransferReport/index")
);
const Influencers = React.lazy(() => import("../pages/influencers/index"));

const PatientWelcomeTemplates = React.lazy(() =>
  import("../pages/patientWelcomeTemplates/index")
);
const PaymentLinkRequests = React.lazy(() =>
  import("../pages/paymentLinkRequests/index")
);

const WhiteListPhoneNumbers = React.lazy(() =>
  import("../pages/whitelistnumbersforcampaigns/index.js")
);

const Coupons = React.lazy(() =>
  import("../pages/coupons/index.js")
);

const inventorySettingsTabs = React.lazy(() =>
  import("../pages/inventorySettingsTabs")
);



const inventoryWorkWarehouses = React.lazy(() =>
  import("../pages/inventoryWork/Warehouses")
);

const inventoryWorkPointOfSaleWarehouses = React.lazy(() =>
  import("../pages/inventoryWork/PointOfSaleWarehouses")
);

import RefundReasons from "../modules/refunds/pages/RefundReasons.js";
import RefundPriorities from "../modules/refunds/pages/RefundPriorities.js";
import RefundTickets from "../modules/refunds/pages/RefundTickets.js";
import RefundTicketsReports from "../modules/refunds/pages/RefundTicketsReports.js";
import TaskPriorities from "../modules/task/pages/TaskPriorities.js";
import TaskType from "../modules/task/pages/TaskType.js";
import TasksNew from "../modules/task/pages/TasksNew.js";
import RefundUserContactVia from "../modules/refunds/pages/RefundUserContactVia";
import RefundTicketDashboard from "../modules/refunds/pages/RefundTicketDashboard";
import TaskDashboad from "../modules/task/pages/TaskDashboad";
import { Typography } from "@material-ui/core";

const RecordProductsLogs = React.lazy(() =>
  import("../pages/recordProductsLogs/index")
);

const RecordProductsLogsGrouped = React.lazy(() =>
  import("../pages/recordProductsLogs/indexGrouped")
);

const RecordProductsLogsByHistory = React.lazy(() =>
  import("../pages/recordProductsLogsByHistory")
);

const UserActivityReport = React.lazy(() =>
  import("../pages/userActivityReport/index")
);

const InvalidPhoneNumbersReport = React.lazy(() =>
  import("../pages/invalidphonenumbers/index")
);
const PaymentsConciliationReport = React.lazy(() =>
  import("../pages/paymentsConciliationReport/index")
);

// Merchants
const ListMerchants = React.lazy(() =>
  import("../pages/merchants/ListMerchants.js")
);
const ListMerchantConfigurations = React.lazy(() =>
  import("../pages/merchants/ListMerchantConfigurations.js")
);
const ListMerchantConsolidationReportGroups = React.lazy(() =>
  import("../pages/merchants/ListMerchantConsolidationReportGroups.js")
);

//Routes

//Auth
const authRoutes = {
  id: "Auth",
  path: "/auth",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
  ],
  component: null,
};

//Home
const homeRoute = {
  id: "Home",
  path: "/",
  component: DashboardRegular,
  children: null,
};

//Dashboard
const dashboardRoute = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <HomeIcon />,
  component: DashboardRegular,
  children: null,
};

//Top Plastic Route
const topPlasticRoute = {
  id: process.env.REACT_APP_COMPANY_OVERVIEW || "Company Overview",
  path: "/top-dashboard",
  icon: <PieChartIcon />,
  permissions: ["dashboard_topPlastic"],
  children: null,
  component: Dashboardnew2,
};

// Accounting Menu
// const accountingRoutes = {
//   id: "Accounting",
//   icon: <AccountBalance />,
//   permissions: ["menu_AccountingMenu"],
//   children: [
//     {
//       path: "/expense-categories",
//       name: "Expenses Categories",
//       component: ExpenseCategory,
//       permissions: ["expensecategory_showMenu"],
//     },
//     {
//       path: "/expense-items",
//       name: "Expenses Items",
//       component: ExpenseItem,
//       permissions: ["expenseitem_showMenu"],
//     },
//     {
//       path: "/expense",
//       name: "Expenses ",
//       component: Expense,
//       permissions: ["expense_showMenu"],
//     },
//     {
//       path: "/cash-box",
//       name: "Cash Boxes ",
//       component: CashBox,
//       permissions: ["cashbox_showMenu"],
//     },
//     {
//       path: "/cash-flow",
//       name: "Cash Flows ",
//       component: CashFlow,
//       permissions: ["cashflow_showMenu"],
//     },
//   ],
//   component: null,
// };

// Inventory Menu
// const inventoryRoutes = {
//   id: "Inventory",
//   icon: <AssignmentTurnedInOutlined />,
//   permissions: ["menu_InventoryMenu"],
//   children: [
//     {
//       path: "/inventory",
//       name: "Inventory Dashboard",
//       component: InvDashboard,
//     },
//     {
//       path: "/inv-whproducts",
//       name: "Warehouses Products",
//       component: InvWhProduct,
//       permissions: ["invwhproduct_showMenu"],
//     },
//     {
//       path: "/inv-whmovements",
//       name: "Movements",
//       component: InvWhMovement,
//       permissions: ["invwhmovement_showMenu"],
//     },
//     {
//       path: "/inv-whmovements-details",
//       name: "Movements Detail",
//       component: InvWhMovementDetail,
//       permissions: ["invwhmovementdetail_showMenu"],
//     },
//     {
//       path: "/inv-warehouses",
//       name: "Warehouses",
//       component: InvWarehouse,
//       permissions: ["invwarehouse_showMenu"],
//     },
//     {
//       path: "/inv-categories",
//       name: "Categories",
//       component: InvCategory,
//       permissions: ["invcategory_showMenu"],
//     },
//     {
//       path: "/inv-products",
//       name: "InvProducts",
//       component: InvProduct,
//       permissions: ["invcategory_showMenu"],
//     },
//     {
//       path: "/inv-providers",
//       name: "Providers",
//       component: InvProvider,
//       permissions: ["invprovider_showMenu"],
//     },
//     {
//       path: "/inv-measures",
//       name: "Measures",
//       component: InvMeasure,
//       permissions: ["invmeasure_showMenu"],
//     },
//     {
//       path: "/inv-localizations",
//       name: "Localizations",
//       component: InvLocalization,
//       permissions: ["invlocalization_showMenu"],
//     },
//     {
//       path: "/inv-warehousetypes",
//       name: "Warehouse Types",
//       component: InvWarehouseType,
//       permissions: ["invwarehousetype_showMenu"],
//     },
//     {
//       path: "/inv-whuserpermission",
//       name: "Inv WH User Permissions",
//       component: InvWhUserPermission,
//       permissions: ["invwhuserpermission_showMenu"],
//     },
//   ],
//   component: null,
// };

// Inventory New Menu
const InventoryNewRoutes = {
  id: "Inventory",
  icon: <AssignmentTurnedInOutlined />,
  roles: ["Permission_DeveloperTeam"],
  // permissions: ["menu_Inventory"],
  children: [ 
    {
      path: "/pointofsalewarehouses",
      name: "Point Of Sale Warehouses",
      component: inventoryWorkPointOfSaleWarehouses,
      permissions: ["menu_PointOfSaleWarehouse"],
    },
    {
      path: "/warehouses",
      name: "Warehouses",
      component: inventoryWorkWarehouses,
      permissions: ["menu_Warehouse"],
    },    
    {
      path: "/inventory-settings",
      name: "Inventory Settings",
      component: inventorySettingsTabs,
      permissions: ["menu_Inventory_Settings"],
    },
  ],
  component: null,
};

// Payroll Center Routes
const PayrollCenterRoutes = {
  id: "Payroll Center",
  icon: <LocalAtmIcon />,
  permissions: ["menu_payrollMenu"],
  children: [
    {
      path: "/payrollcenter-doctorpayroll",
      name: "Doctors Payroll",
      component: PayrollReports3,
      permissions: ["reports_payrolldoctors"],
    },
    {
      path: "/payrollcenter-payrollassistants",
      name: "Payroll Assistant",
      component: PayrollAssistantsReports,
      permissions: ["reports_assistantsPayroll"],
    },
    {
      path: "/payrollcenter-payrollanesthesiologists",
      name: "Payroll Anesthesiologists",
      component: PayrollAnesthesiologistsReports,
      permissions: ["reports_anesthesiologistsPayroll"],
    },
    {
      path: "/payrollcenter-commissionpayroll",
      name: "Commission Payroll",
      component: CommissionPayrollReportTabs,
      permissions: [
        "reports_commissionPayroll",
        "reports_commissionPaidPayroll",
      ],
    },
    {
      path: "/payrollcenter-salesmanagerpayroll",
      name: "Sales Manager Payroll",
      component: SalesManagerPayrollReportTabs,
      permissions: [
        "reports_salesManagerPendingPayroll",
        "reports_salesManagerPaidPayroll",
      ],
    },
    {
      path: "/payrollcenter-salesmanpayroll",
      name: "Salesman Payroll",
      component: SalesmanPayrollReportTabs,
      permissions: [
        "reports_payrollsales",
        "reports_payrollsalesreport",
        "menuadmin_payrollConfigurations",
      ],
    },
    {
      path: "/payrollcenter-coordinationpayroll",
      name: "Coordination Payroll",
      component: CoordinationPayrollReportTabs,
      permissions: [
        "reports_coordinationPendingPayroll",
        "reports_coordinationPaidPayroll",
      ],
    },
    {
      path: "/payrollcenter-coordinationmanagerpayroll",
      name: "Coordination Manager Payroll",
      component: CoordinationManagerPayrollReportTabs,
      permissions: [
        "reports_coordinationMPendingPayroll",
        "reports_coordinationMPaidPayroll",
      ],
    },
    {
      path: "/payrollcenter-payrollConfigurations",
      name: "Salesman Payroll Configurations",
      component: PayrollConfigurationsTabs,
      permissions: ["menuadmin_payrollConfigurations"],
    },
  ],
  component: null,
};

// New Routes

//Sales Menu
const SalesMenu2 = {
  id: "CRM/Sales",
  icon: <PeopleIcon />,
  permissions: ["menu_salesMenu"],
  children: [
    {
      path: "/sales-contacts",
      name: "Contacts/Leads",
      component: SalesMenuContact,
      permissions: ["contact_showMenu"],
    },

    {
      path: "/sales-contacts/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendar,
    },

    {
      path: "/sales-my-approved-contracts",
      name: "My Approved Contracts",
      component: SalesMyContractReport,
      permissions: ["reports_mycontracts"],
    },
    {
      path: "/sales-my-general-contracts-report",
      name: "My General Contracts Report",
      component: SalesMyQuotesReport,
      permissions: ["reports_mycontracts"],
    },
    {
      path: "/sales-doctor-heatmap",
      name: "Doctor Heatmap",
      component: SalesDoctorHeatMap,
      permissions: ["reports_heatmap"],
    },

    {
      path: "/sales-consultantslist",
      name: "Consultants List",
      component: ConsultantsListSales,
      permissions: ["contacts_teamleader", "user_salesGroupManager"],
    },
    {
      path: "/sales-unassigned-contacts",
      name: "Unassigned Contacts",
      component: UnassignedContacts2,

      permissions: ["contacts_assign"],
    },
    //Coordination
    {
      path: "/sales-record-search",
      name: "Patients",
      component: RecordSearchSales,
      permissions: ["reports_recordSearch"],
    },
  ],
  component: null,
};

const InfluencerMenu = {
  id: "CRM/Influencers",
  icon: <PeopleIcon />,
  permissions: ["menu_InfluencerMenu"],
  children: [
    {
      path: "/influencers-contacts",
      name: "Contacts/Leads",
      component: SalesMenuContact,
      permissions: ["contact_showMenu"],
    },
  ],
  component: null,
};

//Sales Menu Reports
const SalesMenu2Report = {
  id: "Reports",
  icon: <AssessmentIcon />,
  permissions: ["menu_SalesmenuReport"],
  children: [
    {
      path: "/sales-quotes-report",
      name: "Quotes Report",
      component: SalesQuotesReport2,
      permissions: ["reports_quotes"],
    },
    {
      path: "/sales-invoices-report",
      name: "Invoices Report",
      component: SalesInvoicesReport2,
      permissions: ["reports_invoices"],
    },
    {
      path: "/sales-invoices-payment-report",
      name: "Invoices Payment Report",
      component: SalesInvoicesPayment,
      permissions: ["reports_invoices"], //new permission needed
    },

    {
      path: "/sales-general-contracts-report",
      name: "General Contracts Report",
      component: SalesMyQuotesReport2,
      permissions: ["reports_contracts"],
    },
    {
      path: "/sales-approved-contracts-report",
      name: "Approved Contracts Report",
      component: SalesContractsReport2,
      permissions: ["reports_contracts"],
    },

    {
      path: "/sales-duplicatecontactsreport",
      name: "Duplicate Contacts Report",
      component: SalesDuplicateContactsReport,
      permissions: ["reports_duplicatecontacts"],
    },
    {
      path: "/sales-lead-performance",
      name: "Lead Performance",
      component: SalesRoundrobin,
      permissions: ["reports_roundrobin"],
    },

    {
      path: "/sales-user-phone-summary-report",
      name: "Call Summary Report",
      component: userSalesPbxSummaryReport,
      permissions: ["user_pbxInformation"],
    },
    {
      path: "/sales-consultants-sales-report",
      name: "Consultants Sales Report",
      component: SalesConsultantsSalesReport,
      permissions: ["reports_consultantssales"],
    },
    {
      path: "/sales-ticketsreport",
      name: "Tickets Report",
      component: SalesTicketsReport,
      permissions: ["reports_tickets"],
    },

    {
      path: "/sales-top-consultants",
      name: "Top Consultants",
      component: SalesTopSellers,
      permissions: ["reports_topConsultants"],
    },
    {
      path: "/sales-top-groups",
      name: "Top Groups",
      component: SalesTopSellersOrg,
      permissions: ["reports_topGroups"],
    },
    {
      path: "/sales-top-doctors",
      name: "Top Doctors",
      component: SalesTopDoctors,
      permissions: ["reports_topDoctors"],
    },
    {
      path: "/sales-fraudulentcontacts",
      name: "Fraudulent Contacts",
      component: FraudolentContacts,
      permissions: ["reports_fraudolentcontacts"],
    },
    {
      path: "/sales-invoiceswithincludereport",
      name: "Package Costs for Invoices",
      component: InvoicesToApplyDiscountReport,
      permissions: ["user_applydoctordiscount"],
    },
    {
      path: "/sales-salesReport",
      name: "Sales Report",
      component: SalesReportsNew2,
      permissions: ["reports_sales"],
    },
    {
      path: "/sales-quotediscountreport",
      name: "Quotes Discount Report",
      component: QuoteDiscountReport,
      permissions: ["reports_quoteDiscountReport"],
    },
    {
      path: "/sales-invoicediscountreport",
      name: "Invoices Discount Report",
      component: InvoiceDiscountReport,
      permissions: ["reports_invoiceDiscountReport"],
    },
    {
      path: "/useractivities",
      name: "User Activities Report",
      component: UserActivityReport,
      permissions: ["reports_useractivity"],
    },
    {
      path: "/invalid-phone-numbers",
      name: "Invalid Phones",
      component: InvalidPhoneNumbersReport,
      permissions: ["reports_invalidphonenumbers"],
    },
    {
      path: "/merchant-transactions-reconciliation",
      name: "Merchant Transactions Reconciliation",
      component: PaymentsConciliationReport,
      permissions: ["reports_paymentsconciliation"],
    },
  ],
  component: null,
};

// New coordination Contacts MarkRoutes
const coordinationContactsMarkRoutes = {
  id: "Coordination",
  icon: <PeopleAltIcon />,
  permissions: ["user_ContactMark"],
  children: [
    {
      path: "/coordinationcontactmark-records",
      name: "Records",
      component: RecordsCoordinationContactMark,
      permissions: ["record_showMenu"],
    },
  ],
  component: null,
};

// Task Routes
const TaskRoutes = {
  id: "Refund Tasks",
  icon: <Iconify icon="jam:task-list-f" />,
  permissions: ["menu_taskspriorities", "menu_taskstypes", "menu_tasks"],
  children: [
    {
      path: "/task-priorities",
      name: "Task Priorities",
      component: TaskPriorities,
      permissions: ["menu_taskspriorities"],
    },
    {
      path: "/task-types",
      name: "Task Types",
      component: TaskType,
      permissions: ["menu_taskstypes"],
    },
    {
      path: "/tasks-new",
      name: "Tasks",
      component: TasksNew,
      permissions: ["menu_tasks"],
    },
    {
      path: "/task-dashboard",
      name: "Task Dashboad",
      component: TaskDashboad,
      permissions: ["menu_tasks"],
    },
  ],
};

// Coordination Menu CRM
const coordinationRoutes2CRM = {
  id: "CRM",
  icon: <PeopleIcon />,
  permissions: ["menu_CoordinationCRMMenu"],
  children: [
    {
      path: "/coordination-contacts",
      name: "Contacts/Leads",
      component: Contacts3Coordinators,
      permissions: ["contact_showMenu"],
    },
    {
      path: "/payment-link-requests",
      name: "Payment Link Requests",
      component: PaymentLinkRequests,
      permissions: ["paymentlinkrequest_showMenu"],
    },
  ],
  component: null,
};

// Coordination Menu
const coordinationRoutes2 = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_coordinationMenu"],
  children: [
    {
      path: "/coordination-records",
      name: "Records",
      component: RecordsCoordination2,
      permissions: ["record_showMenu"],
    },
    {
      path: "/coordination2/contacts/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendarCoordination2,
    },
    {
      path: "/coordination-heatmap",
      name: "Doctor Heatmap",
      component: DoctorHeatMapNewCoordination,
      permissions: ["reports_heatmap"],
    },

    {
      path: "/coordination-timeline",
      name: "Calendar",
      component: ProcedureTimelineCoordination,
    },
    {
      path: "/coordination-refundrequests",
      name: "Refund Requests",
      component: CoordinationRefundRequests2,
      permissions: ["refundrequest_create"],
    },
    {
      path: "/states",
      name: "States",
      component: StatesRouter,
      permissions: ["refundrequest_create"],
      hasInside: true,
    },
    {
      path: "/coordination-doctorlist",
      name: "Doctor List",
      component: DoctorList,
      permissions: ["dashboard_coordinationManager"],
    },
    {
      path: "/coordination-coordinatorslist",
      name: "Coordinators List",
      component: CoordinatorsList,
      permissions: ["dashboard_coordinationManager"],
    },
    {
      path: "/coordination-doctorremarkstemplate",
      name: "Doctor Remarks Template",
      component: doctorRemarksTemplate,
      permissions: ["dashboard_coordinationManager"],
    },
    {
      path: "/coordination-recordproductdatetransfers",
      name: "Surgery Scheduling Requests",
      component: RecordProductDateTransferReport,
      permissions: [
        "recordproduct_transferdate",
        "recordproduct_supertransferdate",
      ],
    },
  ],
  component: null,
};

// Coordination Routes Transfer

const coordinationRoutesTransfer = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  roles: ["Permission_CoordinationTransfer"],
  children: [
    {
      path: "/coordination-records2",
      name: "Records",
      component: RecordsCoordination2,
      roles: ["Permission_CoordinationTransfer"],
    },
  ],
  component: null,
};

// Coordination Menu Reports
const coordinationRoutes2Report = {
  id: "Reports",
  icon: <AssessmentIcon />,
  permissions: ["menu_coordinationMenu"],
  children: [
    {
      path: "/coordination-procedurereports",
      name: "Procedure Report",
      component: RecordProductReports6,
      permissions: ["reports_procedure"],
    },
    {
      path: "/coordination-procedurereportsdashboard",
      name: "Procedure Report Dashboard",
      component: ProcedureReportDashboard,
      permissions: ["reports_procedureReportsDashboard"],
    },
    {
      path: "/coordination-pendingtoschedulereports",
      name: "Pending To Schedule Report",
      component: PendingToScheduleReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/coordination-pendingdoctor",
      name: "Pending Doctor",
      component: NoDoctorReportsCoordination,
      permissions: ["reports_pending"],
    },

    {
      path: "/coordination-pendingtoreschedulereports",
      name: "Pending To Reschedule Report",
      component: PendingToRescheduleReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/coordination-procedurecanceledreports",
      name: "Procedure Canceled Report",
      component: ProcedureCanceledReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/coordination-procedurerefundreports",
      name: "Procedure Refunded Report",
      component: ProcedureRefundReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/coordination-recordproducttransfercord",
      name: "Transfers Report",
      component: RecordProductTransferReportsCoordination,
      permissions: ["reports_recordproducttransfer"],
    },
    {
      path: "/coordination-recordproducttransfer",
      name: "Surgeries Transfer Report",
      component: CoordinationRecordProductTransferReports,
      permissions: ["recordproduct_supertransfer", "recordproduct_transfer"],
    },
    {
      path: "/coordination-ticketsreport",
      name: "Tickets Report",
      component: CoordinationTicketsReport,
      permissions: ["reports_tickets"],
    },
    {
      path: "/coordination-phone-summary-report",
      name: "Call Summary Report",
      component: userPbxSummaryReport,
      permissions: ["user_pbxInformation"],
    },
    {
      path: "/coordination-payments-report",
      name: "Payments Report",
      component: PaymentReportsList,
      permissions: ["reports_payments"],
    },
    {
      path: "/sales-fraudulentcontacts",
      name: "Fraudulent Contacts",
      component: FraudolentContacts,
      permissions: ["reports_fraudolentcontacts"],
    },
    {
      path: "/sales-invoiceswithincludereport",
      name: "Package Costs for Invoices",
      component: InvoicesToApplyDiscountReport,
      permissions: ["user_applydoctordiscount"],
    },
    {
      path: "/coordination-paymentreportsdetails",
      name: "Payments Report by Details",
      component: PaymentReports,
      permissions: ["reports_paymentsdetails"],
    },
    {
      path: "/coordination-paymentreportscash",
      name: "Payments Report Cash by Patients",
      component: PaymentReportsCash,
      permissions: ["reports_paymentsdetails"],
    },
    {
      path: "/coordination-invoices-payment-report",
      name: "Invoices Payment Report",
      component: CoordinationInvoicesPayment,
      permissions: ["reports_invoices"],
    },
    {
      path: "/coordination-approved-contracts-report",
      name: "Approved Contracts Report",
      component: CoordinationContractsReport2,
      permissions: ["reports_contracts"],
    },
    {
      path: "/useractivities",
      name: "User Activities Report",
      component: UserActivityReport,
      permissions: ["reports_useractivity"],
    },
    {
      path: "/invalid-phone-numbers",
      name: "Invalid Phones",
      component: InvalidPhoneNumbersReport,
      permissions: ["reports_invalidphonenumbers"],
    },
    {
      path: "/merchant-transactions-reconciliation",
      name: "Merchant Transactions Reconciliation",
      component: PaymentsConciliationReport,
      permissions: ["reports_paymentsconciliation"],
    },
  ],
  component: null,
};

// Coordination Personal Assistant Menu
const coordinationPARoutes = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_PersonalAssistant"],
  children: [
    {
      path: "/records_pa",
      name: "Records",
      component: RecordsPA,
      permissions: ["menu_PersonalAssistant"],
    },
    {
      path: "/procedurereports_pa",
      name: "Procedure Report",
      component: RecordProductReportsPA,
      permissions: ["menu_PersonalAssistant"],
    },
    {
      path: "/timeline_pa",
      name: "Calendar",
      component: ProcedureTimeline5,
    },
  ],
  component: null,
};

//CallCenter Menu
const CallCenterMenu2 = {
  id: "CRM",
  icon: <PeopleIcon />,
  permissions: ["menu_callCenterMenu"],
  children: [
    {
      path: "/callcenter-contacts",
      name: "Contacts/Leads",
      component: CallCenterCoordinatorsCRM,
      permissions: ["contact_showMenu"],
    },
    {
      path: "/call-center/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendar,
    },
    {
      path: "/payment-link-requests",
      name: "Payment Link Requests",
      component: PaymentLinkRequests,
      permissions: ["paymentlinkrequest_showMenu"],
    },
  ],
  component: null,
};
//CallCenter Menu Coordination
const CallCenterMenu2Coordination = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_callCenterMenu"],
  children: [
    {
      path: "/callcenter-records",
      name: "Patients",
      component: RecordSearchCallCenter,
      permissions: ["reports_recordSearch"],
    },
  ],
  component: null,
};

//CallCenter Menu
const CallCenterMenu2CallCenter = {
  id: "Call Center",
  icon: <DialpadIcon />,
  permissions: ["dashboard_callCenterManager"],
  children: [
    {
      path: "/callcenter-agentlist",
      name: "Agent List",
      component: CallCenterAgentList,
      permissions: ["dashboard_callCenterManager"],
    },
  ],
  component: null,
};

//CallCenter Menu Report
const CallCenterMenu2Report = {
  id: "Call Center Reports",
  icon: <AssessmentIcon />,
  permissions: ["dashboard_callCenterManager", "user_pbxInformation"],
  children: [
    {
      path: "/callcenter-phone-summary-report",
      name: "Call Summary Report",
      component: callCenterPbxSummaryReport,
      permissions: ["user_pbxInformation"],
    },
  ],
  component: null,
};

//FrontDesk Menu CRM
const FrontDeskMenu2 = {
  id: "CRM",
  icon: <PeopleIcon />,
  permissions: ["menu_frontdeskMenu"],
  children: [
    {
      path: "/frontdesk-contacts",
      name: "Contacts/Leads",
      component: CallCenterCoordinatorsCRM,
      permissions: ["contact_showMenu"],
    },
    {
      path: "/front-desk/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendar,
    },
    {
      path: "/payment-link-requests",
      name: "Payment Link Requests",
      component: PaymentLinkRequests,
      permissions: ["paymentlinkrequest_showMenu"],
    },
  ],
  component: null,
};

//FrontDesk Menu Coordination
const FrontDeskMenu2Coordination = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_frontdeskMenu"],
  children: [
    {
      path: "/frontdesk-records",
      name: "Patients",
      component: RecordSearchCallCenter,
      permissions: ["reports_recordSearch"],
    },
    {
      path: "/frontdesk-procedurereports",
      name: "Procedure Report",
      component: RecordProductReports6,
      permissions: ["reports_procedure"],
    },
  ],
  component: null,
};

//FrontDesk Menu
const FrontDeskMenu2FrontDesk = {
  id: "Front Desk",
  icon: <ContactsIcon />,
  permissions: ["dashboard_frontDeskManager"],
  children: [
    {
      path: "/frontdesk-agentlist",
      name: "Agent List",
      component: FrontDeskAgentList,
      permissions: ["dashboard_frontDeskManager"],
    },
  ],
  component: null,
};

//FrontDesk Menu Reports
const FrontDeskMenu2Report = {
  id: "Reports",
  icon: <AssessmentIcon />,
  permissions: ["dashboard_frontDeskManager" /* , "reports_payments" */],
  children: [
    {
      path: "/frontdesk-phone-summary-report",
      name: "Call Summary Report",
      component: callCenterPbxSummaryReport,
      permissions: ["user_pbxInformation"],
    },
    /*{
      path: "/frontdesk-paymentreportslist",
      name: "Payments Report",
      component: PaymentReportsList,
      permissions: ["reports_payments"],
    },*/
  ],
  component: null,
};

//SocialMedia Menu CRM
const SocialMediaMenu2 = {
  id: "CRM",
  icon: <PeopleIcon />,
  permissions: ["menu_socialmediaMenu"],
  children: [
    {
      path: "/socialmedia-contacts",
      name: "Contacts/Leads",
      component: CallCenterCoordinatorsCRM,
      permissions: ["contact_showMenu"],
    },
    {
      path: "/social-media/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendar,
    },
    {
      path: "/social-lead-distribution",
      name: "Lead Distribution",
      component: ContactDistribution,
      permissions: ["reports_inleads"],
    },
    {
      path: "/social-lead-performance",
      name: "Lead Performance",
      component: Roundrobin,
      permissions: ["reports_roundrobin"],
    },
    {
      path: "/payment-link-requests",
      name: "Payment Link Requests",
      component: PaymentLinkRequests,
      permissions: ["paymentlinkrequest_showMenu"],
    },
  ],
  component: null,
};

//SocialMedia Menu Coordination
const SocialMediaMenu2Coordination = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_socialmediaMenu"],
  children: [
    {
      path: "/socialmedia-records",
      name: "Patients",
      component: RecordSearchCallCenter,
      permissions: ["reports_recordSearch"],
    },
    {
      path: "/socialmedia-coordination-timeline",
      name: "Calendar",
      component: ProcedureTimelineSocialMedia,
      permissions: ["reports_socialmediaCalendar"],
    },
  ],
  component: null,
};

//SocialMedia Menu
const SocialMediaMenu2SocialMedia = {
  id: "Social Media",
  icon: <DialpadIcon />,
  permissions: ["dashboard_socialMediaManager"],
  children: [
    {
      path: "/socialmedia-agentlist",
      name: "Agent List",
      component: SocialMediaAgentList,
      permissions: ["dashboard_socialMediaManager"],
    },
  ],
  component: null,
};

//Billing Menu CRM
const BillingMenu2 = {
  id: "CRM",
  icon: <PeopleIcon />,
  permissions: ["menu_billingMenu"],
  children: [
    {
      path: "/billing-contacts",
      name: "Contacts/Leads",
      component: ContactSearchCRM,
      permissions: ["contact_showMenu"],
    },
    /*  {
      path: "/billing-managercontacts",
      name: "Contacts/Leads",
      component: BillingManagerContacts3,
      permissions: ["dashboard_billingManager"],
    },*/
    {
      path: "/billing/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendar,
    },
    {
      path: "/payment-link-requests",
      name: "Payment Link Requests",
      component: PaymentLinkRequests,
      permissions: ["paymentlinkrequest_showMenu"],
    },
  ],
  component: null,
};

//Billing Menu Coordination
const BillingMenu2Coordination = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_billingMenu"],
  children: [
    {
      path: "/billing-records",
      name: "Patients",
      component: RecordSearchCallCenter,
      permissions: ["reports_recordSearch"],
    },
  ],
  component: null,
};

//Billing Menu
const BillingMenu2Billing = {
  id: "Billing",
  icon: <ContactsIcon />,
  permissions: ["dashboard_billingManager"],
  children: [
    {
      path: "/billing-agentlist",
      name: "Agent List",
      component: FrontDeskAgentList,
      permissions: ["dashboard_billingManager"],
    },
  ],
  component: null,
};

//Billing Menu Reports
const BillingMenu2Report = {
  id: "Reports",
  icon: <AssessmentIcon />,
  permissions: ["menu_billingMenu"],
  children: [
    {
      path: "/billing-quotes-report",
      name: "Quotes Report",
      component: BillingQuotesReport2,
      permissions: ["reports_quotes"],
    },
    {
      path: "/billing-invoices-report",
      name: "Invoices Report",
      component: BillingInvoicesReport2,
      permissions: ["reports_invoices"],
    },
    {
      path: "/billing-approved-contracts-report",
      name: "Approved Contracts Report",
      component: BillingContractsReport2,
      permissions: ["reports_contracts"],
    },
    {
      path: "/billing-general-contracts-report",
      name: "General Contracts Report",
      component: BillingMyQuotesReport2,
      permissions: ["reports_contracts"],
    },
    {
      path: "/billing-procedurereports",
      name: "Procedure Report",
      component: BillingProductReports6,
      permissions: ["reports_procedure"],
    },

    // {
    //   path: "/billing-commissionrulesreport",
    //   name: "Commission Reports",
    //   component: CommissionRulesReport,
    //   permissions: ["reports_commissionRules"],
    // },
    {
      path: "/billing-payrollreports",
      name: "Doctors Payroll Report",
      component: PayrollReports3,
      permissions: ["reports_payroll"],
    },
    {
      path: "/billing-paymentsreportslist",
      name: "Payments Report",
      component: PaymentReportsList,
      permissions: ["reports_payments"],
    },

    {
      path: "/billing-paymentreportsdetails",
      name: "Payments Report by Details",
      component: PaymentReports,
      permissions: ["reports_paymentsdetails"],
    },
    {
      path: "/billing-paymentreportshistory",
      name: "Payments Report History",
      component: PaymentReportsHistory,
      permissions: ["reports_paymentshistory"],
    },
    {
      path: "/billing-recordproducttransfer",
      name: "Surgeries Transfer Report",
      component: CoordinationRecordProductTransferReports,
      permissions: [
        "dashboard_billingManager",
        "dashboard_billingManagerAssistant",
      ],
    },
    {
      path: "/billing-paymentsactivityreports",
      name: "Payment Activity Report",
      component: PaymentsActivityReportsNew,
      permissions: ["reports_paymentsActivity"],
    },
    {
      path: "/billing-ticketsreport",
      name: "Tickets Report",
      component: TicketsReport,
      permissions: ["reports_tickets"],
    },
    {
      path: "/billing-salesReport",
      name: "Sales Report",
      component: SalesReportsNew2,
      permissions: ["reports_sales"],
    },
    {
      path: "/billing-creditreport",
      name: "Credit Report",
      component: CreditReport,
      permissions: ["reports_creditReport"],
    },
    {
      path: "/billing-paymentreportscash",
      name: "Payments Report",
      component: PaymentReportsList,
      permissions: ["dashboard_billingAssistant"],
    },
    {
      path: "/sales-fraudulentcontacts",
      name: "Fraudulent Contacts",
      component: FraudolentContacts,
      permissions: ["reports_fraudolentcontacts"],
    },
    {
      path: "/sales-invoiceswithincludereport",
      name: "Package Costs for Invoices",
      component: InvoicesToApplyDiscountReport,
      permissions: ["user_applydoctordiscount"],
    },
    {
      path: "/useractivities",
      name: "User Activities Report",
      component: UserActivityReport,
      permissions: ["reports_useractivity"],
    },
    {
      path: "/invalid-phone-numbers",
      name: "Invalid Phones",
      component: InvalidPhoneNumbersReport,
      permissions: ["reports_invalidphonenumbers"],
    },
    {
      path: "/merchant-transactions-reconciliation",
      name: "Merchant Transactions Reconciliation",
      component: PaymentsConciliationReport,
      permissions: ["reports_paymentsconciliation"],
    },
  ],
  component: null,
};

// Financing Menu
const FinancingRoutes = {
  id: "Financing",
  icon: <AccountBalanceIcon />,
  permissions: ["menu_financingMenu"],
  children: [
    {
      path: "/financing-contacts",
      name: "Contacts/Leads",
      component: FinancingContact,
      permissions: ["reports_contactFinancing"],
    },
  ],
  component: null,
};

//Tickets Route
const TicketRoutes = {
  id: "Tickets",
  icon: <ConfirmationNumberIcon />,
  permissions: ["menu_TicketMenu"],

  children: [
    {
      path: "/tickets",
      name: "Tickets",
      component: Tikets,
      permissions: ["tickets_showMenu"],
    },
    {
      path: "/mytickets",
      name: "My Tickets User",
      component: MyTikets,
      permissions: ["tickets_MyTickets"],
    },
    {
      path: "/ticketsagent",
      name: "My Tickets Agent",
      component: TicketsAgent,
      permissions: ["tickets_MyTickets"],
    },
    {
      path: "/ticket-agents-report",
      name: "Ticket Agents Report",
      component: TicketsResponsibleReports,
      permissions: ["tickets_AgentsReports"],
    },
    {
      path: "/ticket-users-reports",
      name: "Ticket User Report",
      component: TicketUsersReports,
      permissions: ["tickets_UsersReports"],
    },
  ],
  component: null,
};

// Accounting Reports Routes
const AccountingReportsRoutes = {
  id: "Accounting Reports",
  icon: <CreditCardIcon />,
  permissions: ["menu_AccountingReportsMenu"],
  children: [
    {
      path: "/accountingsalesreportR",
      name: "Accounting Sales Report",
      component: AccountingSalesReport,
      permissions: ["reports_accountingSalesReport"],
    },
    {
      path: "/accountingsalesreportbyperiodR",
      name: "Accounting Sales Report By Period",
      component: AccountingSalesReportNew,
      permissions: ["reports_accountingSalesReport"],
    },
    {
      path: "/accounting-dashboardnew",
      name: "Dashboard New",
      component: Dashboardnew2,
      permissions: ["reports_accountingDashboardNew"],
    },

    {
      path: "/accounting-quotesreport",
      name: "Quotes Report",
      component: QuotesReport2,
      permissions: ["reports_quotes"],
    },
    {
      path: "/accounting-invoicesreport",
      name: "Invoices Report",
      component: InvoicesReport2,
      permissions: ["reports_invoices"],
    },
    {
      path: "/accounting-invoicespaymentreport",
      name: "Invoices Payment Report",
      component: InvoicesPayment,
      permissions: ["reports_invoices"],
    },
    {
      path: "/accounting-payrollreports",
      name: "Doctors Payroll Report",
      component: PayrollReports3,
      permissions: ["reports_payroll"],
    },
    {
      path: "/accounting-payrollgroupedreport",
      name: "Payroll Report By Patient",
      component: PayrollGroupedReports,
      permissions: ["reports_payrollgrouped"],
    },

    {
      path: "/accounting-paymentreportslist",
      name: "Payments Report",
      component: PaymentReportsList,
      permissions: ["reports_payments"],
    },

    {
      path: "/accounting-paymentreportsdetails",
      name: "Payments Report by Details",
      component: PaymentReports,
      permissions: ["reports_paymentsdetails"],
    },
    {
      path: "/accounting-paymentreportshistory",
      name: "Payments Report History",
      component: PaymentReportsHistory,
      permissions: ["reports_paymentshistory"],
    },
    {
      path: "/accounting-lead-distribution",
      name: "Lead Distribution",
      component: ContactDistribution,
      permissions: ["reports_roundrobin"],
    },

    {
      path: "/accounting-procedurereports",
      name: "Procedure Report",
      component: RecordProductReports6,
      permissions: ["reports_procedure"],
    },

    {
      path: "/accounting-pendingtoreschedulereports",
      name: "Pending To Reschedule Report",
      component: PendingToRescheduleReports,
      permissions: ["reports_pending"],
    },
    {
      path: "/accounting-duplicatecontactsreport",
      name: "Duplicate Contacts Report",
      component: DuplicateContactsReport,
      permissions: ["reports_duplicatecontacts"],
    },

    {
      path: "/accounting-recordproducttransfer",
      name: "Surgeries Transfer Report",
      component: RecordProductTransferReports,
      permissions: ["recordproduct_supertransfer", "recordproduct_transfer"],
    },

    {
      path: "/accounting-paymentsactivityreports",
      name: "Payment Activity Report",
      component: PaymentsActivityReportsNew,
      permissions: ["reports_paymentsActivity"],
    },
    {
      path: "/accounting-user-phone-summary-report",
      name: "Call Summary Report",
      component: userPbxSummaryReport,
      permissions: ["user_pbxInformation"],
    },
    {
      path: "/accounting-consultantssalesreport",
      name: "Consultants Sales Report",
      component: ConsultantsSalesReport,
      permissions: ["reports_consultantssales"],
    },
    {
      path: "/accounting-surgeoncontracts",
      name: "Surgeon Contracts Report",
      component: SurgeonQuotesRecordProductReports,
      permissions: ["reports_surgeoncontracts"],
    },

    {
      path: "/accounting-massagescontrolreport",
      name: "Massages Control Report",
      component: ContactsMassagesControlReport,
      permissions: ["reports_massagescontrol"],
    },
    {
      path: "/accounting-refundslist",
      name: "Refunds List",
      component: RefundChargeBackReport,
      permissions: ["reports_refundchargeback"],
    },
    {
      path: "/accounting-chargebackriskreport",
      name: "Chargeback Risk Report",
      component: RefundsChargeBackRisk,
      permissions: ["reports_refundchargeback"],
    },

    {
      path: "/accounting-ticketsreport",
      name: "Tickets Report",
      component: TicketsReport,
      permissions: ["reports_tickets"],
    },
    {
      path: "/accounting-salesReport",
      name: "Sales Report",
      component: SalesReportsNew2,
      permissions: ["reports_sales"],
    },
    {
      path: "/accounting-salesReportWithDoctor",
      name: "Sales Report With Doctor",
      component: SalesReportsNewWithDoctor,
      permissions: ["reports_saleswithdoctor"],
    },
    {
      path: "/accounting-summaryclosingrate",
      name: "Summary Closing Rate",
      component: TopBottomSellers,
      permissions: ["reports_sellerssummary"],
    },
    {
      path: "/accounting-productsControl",
      name: "Products Control",
      component: ContactsProductsNoProcedure,
      permissions: ["reports_productscontrol"],
    },
    {
      path: "/accounting-productgiftreport",
      name: "Product Gift Report",
      component: ProductGiftReport,
      permissions: ["reports_giftreport"],
    },
    {
      path: "/accounting-creditreport",
      name: "Credit Report",
      component: CreditReport,
      permissions: ["reports_creditReport"],
    },
    {
      path: "/accounting-reportedcontacts",
      name: "Reported Contacts",
      component: BadContactReport,
      permissions: ["reports_badcontacts"],
    },
    {
      path: "/accounting-procedurecanceledreports",
      name: "Procedure Canceled Report",
      component: ProcedureCanceledReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/accounting-procedurerefundreports",
      name: "Procedure Refunded Report",
      component: ProcedureRefundReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/accounting-contactspatientsbyzipcode",
      name: "Patients By ZipCode",
      component: ContactsPatientsStateZipCode,
      permissions: ["reports_contactrecordzip"],
    },
  ],
  component: null,
};

// Marketing Routes
const MarketingRoutes = {
  id: "Marketing",
  icon: <Iconify icon="nimbus:marketing" />,
  permissions: ["menu_Marketing"],
  children: [
    {
      path: "/campaigns_dashboard",
      name: "Campaigns Dashboard",
      component: CampaignsDashboard,
      permissions: ["marketing_menuassignedcampaignsdashboard"],
    },
    {
      path: "/campaign_templates_tabs",
      name: "Campaign Design",
      component: CampaignsDesignTabs,
      permissions: ["marketing_menutemplates", "marketing_menucampaignforms"],
    },
    // {
    //   path: "/campaigns_dashboard_old",
    //   name: "Campaigns Dashboard",
    //   component: AssignedCampaignsDashboard,
    //   permissions: ["marketing_menuassignedcampaignsdashboard"],
    // },
    // {
    //   path: "/campaign_templates",
    //   name: "Campaign Templates",
    //   component: CampaignsTemplates,
    //   permissions: ["marketing_menutemplates"],
    // },
    // {
    //   path: "/campaigns",
    //   name: "Campaign Content",
    //   component: Campaigns,
    //   permissions: ["marketing_menucampaignforms"],
    // },
    {
      path: "/campaign_contacts",
      name: "Assign Campaign",
      component: ContactsForCampaigns,
      permissions: ["marketing_menucontacts"],
    },
    {
      path: "/status_campaigns",
      name: "Campaigns Status",
      component: AssignedCampaigns,
      permissions: ["marketing_menucampaigns"],
    },

    {
      path: "/scheduled_campaigns_priorities",
      name: "Campaign Priorities",
      component: AssignedCampaignsPriorities,
      permissions: ["marketing_menuassignedcampaignpriority"],
    },

    {
      path: "/contact-metadata-report",
      name: "Contact Metadata Report",
      component: ContactMetadataReport,
      permissions: ["marketing_contactMetadataReport"],
    },
    {
      path: "/influencers",
      name: "Influencers",
      component: Influencers,
      permissions: ["marketing_menuinfluencers"],
    },
    {
      path: "/influencer-contracts",
      name: "Influencer Contracts",
      component: InfluencerContracts,
      permissions: ["marketing_menuinfluencercontracts"],
    },
    {
      path: "/marketing-campaign-credits",
      name: "Campaign Credits",
      component: CampaignCredits,
      permissions: ["menuadmin_campaigncredits"],
    },
  ],
  component: null,
};

// Directory Routes
const DirectoryRoutes = {
  id: "Directory",
  icon: <GroupIcon />,
  permissions: ["user_directory"],
  children: [
    {
      path: "/directory",
      name: "Users",
      component: UsersDirectory,
      permissions: ["user_directory"],
    },
    {
      path: "/doctor-remarks",
      name: "Doctors Remarks ",
      component: DoctorsRemarks,
      permissions: ["user_directory"],
    },
    {
      path: "/products-list",
      name: "Products/Services List",
      component: ProductsList,
      permissions: ["products_directory"],
    },
  ],
  component: null,
};

// Analytics Routes
const AnalyticsRoutes = {
  id: "Analytics",
  icon: <AssessmentIcon />,
  permissions: ["menu_Analytics"],
  children: [
    {
      path: "/contactsbysource",
      name: "Contacts By Source",
      component: ContactsBySource,
      permissions: ["reports_contactsbysource"],
    },
    {
      path: "/lead-performance",
      name: "Lead Performance",
      component: Roundrobin,
      permissions: ["reports_roundrobin"],
    },
    {
      path: "/top-consultants",
      name: "Top Consultants",
      component: TopSellers,
      permissions: ["reports_topConsultants"],
    },
    {
      path: "/top-groups",
      name: "Top Groups",
      component: TopSellersOrg,
      permissions: ["reports_topGroups"],
    },
    {
      path: "/top-groups-by-money",
      name: "Top Groups By Money",
      component: TopSellersOrgByMoney,
      roles: ["admin", "super-admin"],
      //permissions: ["reports_topGroups"],
    },
    {
      path: "/top-doctors",
      name: "Top Doctors",
      component: TopDoctors,
      permissions: ["reports_topDoctors"],
    },
    {
      path: "/top-sources",
      name: "Top Sources",
      component: TopSources,
      permissions: ["reports_topDoctors"],
    },
    {
      path: "/contact-sources-report",
      component: ContactSourcesReport,
      name: "Organization Sources Report",
      //permissions: ["reports_topDoctors"],
      roles: ["admin", "super-admin"],
    },
    {
      path: "/closing-speed",
      name: "Closing Speed Avg.",
      component: TopClosingSpeed,
      permissions: ["reports_topGroups"],
    },
    {
      path: "/refunds-report",
      name: "Refunds Report",
      component: RefundReports,
      permissions: ["reports_topDoctors"],
    },
    {
      path: "/lead-distribution-analytics",
      name: "Lead Distribution",
      component: ContactDistribution,
      permissions: ["reports_roundrobin"],
      roles: ["admin", "super-admin"],
    },
    {
      path: "/top-sellers-by-calls",
      name: "Top Groups By Calls",
      component: TopSellersOrgByCalls,
      permissions: ["user_pbxInformation"],
      roles: ["admin", "super-admin"],
    },
    {
      path: "/procedureanalytics",
      name: "Procedure Analytics Report",
      component: ProcedureAnalyticsReport,
      permissions: ["reports_procedureAnalytics"],
    },
  ],
  component: null,
};

// Refunds Routes
const RefundsRoutes = {
  id: "Refund Tickets",
  icon: <Iconify icon="ion:card" />,
  permissions: [
    "menu_refundsticketsreasons",
    "menu_refundsticketspriorities",
    "menu_refundstickets",
    "menu_refundsticketsreports",
  ],
  children: [
    {
      path: "/refunds-reasons-new",
      name: "Refunds Reasons",
      component: RefundReasons,
      permissions: ["menu_refundsticketsreasons"],
    },
    {
      path: "/refunds-priorities",
      name: "Refunds Priorities",
      component: RefundPriorities,
      permissions: ["menu_refundsticketspriorities"],
    },
    {
      path: "/refunds-tickets",
      name: "Refunds Tickets",
      component: RefundTickets,
      permissions: ["menu_refundstickets"],
    },
    {
      path: "/refunds-tickets-dashboard",
      name: "Refunds Tickets Reports",
      component: RefundTicketDashboard,
      permissions: ["menu_refundsticketsreports"],
    },
    {
      path: "/refunds-user-contact-via",
      name: "Refunds User Contact Via",
      component: RefundUserContactVia,
      permissions: ["menu_refundscontactvia"],
    },
  ],
};

// TaskRoutes
//   id: "Refund Tasks",
//   icon: <Iconify icon="jam:task-list-f" />,
//   permissions: ["menu_taskspriorities", "menu_taskstypes", "menu_tasks"],
//   children: [
//     {
//       path: "/task-priorities",
//       name: "Task Priorities",
//       component: TaskPriorities,
//       permissions: ["menu_taskspriorities"],
//     },
//     {
//       path: "/task-types",
//       name: "Task Types",
//       component: TaskType,
//       permissions: ["menu_taskstypes"],
//     },
//     {
//       path: "/tasks-new",
//       name: "Tasks",
//       component: TasksNew,
//       permissions: ["menu_tasks"],
//     },
//   ],
// };

// Communication Center
const messageRoutes = {
  id: "Communication Center",
  icon: <ForumIcon />,
  permissions: ["menu_CommunicationCenterMenu"],
  children: [
    {
      path: "/messages",
      name: "Messages",
      component: Messages,
    },
    {
      path: "/messagessms",
      name: "Messages SMS/MMS",
      component: MessagesSMS,
      permissions: ["user_sms"],
    },
    {
      path: "/messagescenter",
      name: "Messages Center",
      component: MessagesCenter,
      roles: ["Permission_DeveloperTeam"],
    },
    {
      path: "/callshistory",
      name: "Calls History",
      component: UserCallHistory,
      permissions: ["user_sms"],
    },
    {
      path: "/smsreport",
      name: "SMS/MMS Report",
      component: SMSReport,
      permissions: ["reports_smsmms"],
    },
    {
      path: "/callsreport",
      name: "Calls Report",
      component: CallsReport,
      permissions: ["reports_calls"],
    },
    {
      path: "/orgcallshistory",
      name: "Calls History Report",
      component: CallOrgHistory,
      permissions: ["reports_callHistoryOrg"],
    },
    {
      path: "/internalchat",
      name: "Internal Chat",
      component: InternalChatPage,
      // permissions: ["reports_internalchatreport"],
    },
    {
      path: "/internalchat-report",
      name: "Internal Chat Report",
      component: InternalChatReport,
      permissions: ["reports_internalchatreport"],
    },
    {
      path: "/blocked-chat-users",
      name: "Blocked Chat Users",
      component: BlockedChatUsersReport,
      permissions: ["reports_internalchatblockedusers"],
    },
    {
      path: "/unanswered-smsmms-by-user",
      name: "Unanswered SMS/MMS",
      component: UnansweredSMSMMS,
      permissions: ["reports_unansweredsmsmms"],
    },
  ],
  component: null,
};

// appointments new
const appointmentsnew = {
  id: "Appointments",
  icon: <TodayIcon />,
  permissions: [
    "appointmentsnew_viewappointments",
    "appointmentsnew_report",
    "appointmentsnew_viewdashboard",
    "appointmentsnew_checkin",
    "appointmentsnew_edittypes",
    "appointmentsnew_deletetypes",
    "appointmentsnew_createcancelationreasons",
    "appointmentsnew_deletecancelationreasons",
    "appointmentsnew_createmovementreasons",
    "appointmentsnew_deletemovementreasons",
  ],
  children: [
    {
      path: "/appointmentsnewdashboard",
      name: "Dashboard",
      component: AppointmentNewDashboard,
      permissions: ["appointmentsnew_viewdashboard"],
    },
    {
      path: "/appointmentsnew",
      name: "Appointments",
      component: AppointmentNew,
      permissions: ["appointmentsnew_viewappointments"],
    },
    {
      path: "/appointmentstodaylist",
      name: "Today's List",
      component: AppointmentsTodayList,
      permissions: ["appointmentsnew_checkin"],
    },

    {
      path: "/appointmentsdailymassagerecord",
      name: "Daily Massages",
      component: DailyMassageRecord,
      permissions: ["appointmentsnew_dailyrecord"],
    },
    {
      path: "/appointmentspendingreport",
      name: "Pending Appointments",
      component: PendingAppointmentsReport,
      permissions: ["appointmentsnew_report"],
    },
    {
      path: "/appointmentsreport",
      name: "Appointments Report",
      component: AppointmentsReport,
      permissions: ["appointmentsnew_report"],
    },
    {
      path: "/appointmenttypes",
      component: AppointmentTypes,
      name: "Appointment Types",
      permissions: ["appointmentsnew_edittypes", "appointmentsnew_deletetypes"],
    },
    {
      path: "/appointmentscancellationreason",
      name: "Cancellation Reasons",
      component: AppointmentsCancelationReason,
      permissions: [
        "appointmentsnew_createcancelationreasons",
        "appointmentsnew_deletecancelationreasons",
      ],
    },
    {
      path: "/appointmentsmovementsreason",
      name: "Movement Reasons",
      component: AppointmentsMovementsReason,
      permissions: [
        "appointmentsnew_createmovementreasons",
        "appointmentsnew_deletemovementreasons",
      ],
    },
  ],
  component: null,
};

// Merchants Routes
const merchantsRoutes = {
  id: "Merchants",
  icon: <Iconify icon="tabler:transaction-dollar" />,
  permissions: ["merchants_menumerchants"],
  children: [
    {
      path: "/merchants",
      name: "Merchants",
      component: ListMerchants,
      permissions: ["merchants_listmerchants"],
    },
    {
      path: "/reconciliation-report-group",
      name: "Reconciliation Report Groups",
      component: ListMerchantConsolidationReportGroups,
      permissions: ["merchants_listconsolidationreportgroup"],
    },
    {
      path: "/merchant-configurations",
      name: "Merchant Configurations",
      component: ListMerchantConfigurations,
      permissions: ["merchants_listconfigurations"],
    },
  ],
};

const ReportsCenterNewRoutes = {
  id: "Reports Center (New)",
  icon: <Iconify icon="icon-park-solid:table-report" />,
  roles: ["Permission_DeveloperTeam"],
  children: [
    {
      path: "/analytics-report",
      name: "*Analytics Report*",
      component: AnalyticsReport,
      permissions: ["reports_procedureAnalytics"],
    },
  ],
};

// OLD MENU

// Old Appointments Menu
const AppointmentsRoutes = {
  id: "Appointments Old",
  icon: <ConfirmationNumberIcon />,
  permissions: ["menu_AppointmentsMenu"],

  children: [
    {
      path: "/appointments",
      name: "Appointments",
      component: Appointments,
      permissions: ["menu_AppointmentsMenu"],
    },
  ],
  component: null,
};

//Old Menu Admin Route
const adminRoutes = {
  id: "Admin",
  icon: <TuneIcon />,
  permissions: ["menu_AdminMenu"],
  children: [
    {
      path: "/accesscontrollist",
      name: "Access Control List",
      component: WhiteBlackRules,
      permissions: ["menuadmin_whiteblackrules"],
    },
    {
      path: "/admintools",
      name: "Admin Tools",
      component: AdminTools,
      permissions: ["menuadmin_admintools"],
    },
    {
      path: "/appointments-motives",
      name: "Appointments Motives",
      component: AppointmentsMotives,
      permissions: ["menuadmin_appointmentsmotives"],
    },
    {
      path: "/clientssummary",
      name: "Building IPs",
      component: ClientsList,
      permissions: ["menuadmin_clientssummary"],
    },
    {
      path: "/buildings",
      name: "Buildings",
      component: Buildings,
      permissions: ["menuadmin_buildings"],
    },
    {
      path: "/cancellation-motives",
      name: "Cancellation Motives",
      component: CancellationMotives,
      permissions: ["menuadmin_cancellationmotives"],
    },
    {
      path: "/categories",
      name: "Categories",
      component: Categories2,
      permissions: ["menuadmin_categories"],
    },
    {
      path: "/commentcategories",
      name: "Comment Categories",
      component: CommentCategories,
      permissions: ["menuadmin_commentcategories"],
    },
    // {
    //   path: "/commission-rules",
    //   name: "Commission Rules",
    //   component: CommissionRules,
    //   permissions: ["menuadmin_commissionrules"],
    // },
    {
      path: "/communication-groups",
      name: "Communication Groups",
      component: CommunicationGroups,
      permissions: ["menuadmin_communicationgroups"],
    },
    {
      path: "/form-templates",
      name: "Contact Form Templates",
      component: contactFormTemplates,
      permissions: ["menuadmin_formtemplates"],
    },
    {
      path: "/form-templates-categories",
      name: "Contact Form Templates Categories",
      component: contactFormTemplatesCategories,
      permissions: ["menuadmin_formtemplate_categories"],
    },
    {
      path: "/contact-metadata-categories",
      name: "Contact Metadata Categories",
      component: ContactMetadataCategories,
      permissions: ["menuadmin_contactMetadataCategory"],
    },
    {
      path: "/contracts",
      name: "Contracts",
      component: Contracts,
      permissions: ["menuadmin_contracts"],
    },
    {
      path: "/creditmemoreasons",
      name: "Credit Memo Reasons",
      component: CreditMemoReasons,
      permissions: ["menuadmin_creditmemoreasons"],
    },
    {
      path: "/custom-fields",
      name: "Custom Fields",
      component: CustomFields,
      permissions: ["menuadmin_customfields"],
    },
    {
      path: "/deactivatehistory",
      name: "Deactivate History",
      component: DeactivateHistory,
      permissions: ["menuadmin_deactivatehistory"],
    },
    {
      path: "/deactivatereasons",
      name: "Deactivate Reasons",
      component: DeactivateReasons,
      permissions: ["menuadmin_deactivatereasons"],
    },
    {
      path: "/depatments",
      name: "Departments",
      component: Departments,
      permissions: ["menuadmin_depatments"],
    },
    {
      path: "/users-doctors",
      name: "Doctors",
      component: UsersDoctors,
      permissions: ["menuadmin_manageDoctors"],
    },
    {
      path: "/documents-types",
      name: "Document Types",
      component: DocumentTypes,
      permissions: ["menuadmin_documenttypes"],
    },
    {
      path: "/faqs",
      name: "Faqs",
      component: AdmFaq,
      permissions: ["menuadmin_faqs"],
    },
    {
      path: "/financing",
      name: "Financing",
      component: Financing,
      permissions: ["menuadmin_financing"],
    },
    {
      path: "/fraudulentcontactsreasons",
      name: "Fraud Reasons",
      component: FraudReasons,
      roles: ["admin", "super-admin"], // to do permission
    },
    {
      path: "/groups",
      name: "Groups",
      component: Groups,
      permissions: ["menuadmin_groups"],
    },
    {
      path: "/round-robin",
      name: "Leads Assigment",
      component: Roundrobin,
      permissions: ["menuadmin_roundrobin"],
    },
    {
      path: "/organizations",
      name: "Organizations",
      component: Organizations,
      permissions: ["menuadmin_organizations"],
    },
    {
      path: "/payment-gateways",
      name: "Payment Gateways",
      component: PaymentGateways,
      permissions: ["menuadmin_paymentgateways"],
    },
    {
      path: "/polls",
      name: "Polls",
      component: PollRouter,
      hasInside: true,
      permissions: ["menuadmin_polls"],
    },
    {
      path: "/chart-document-categories",
      name: "Chart Document Categories",
      component: TemplatesCategories,
      permissions: ["menuadmin_templatescategories"],
    },
    {
      path: "/chart-document-templates",
      name: "Chart Document Templates",
      component: recordProductTemplates,
      permissions: ["menuadmin_proceduretemplates"],
    },
    {
      path: "/product-gift-reasons",
      name: "Product Gift Reason",
      component: ProductGiftReason,
      permissions: ["menuadmin_productgiftreasons"],
    },
    {
      path: "/products",
      name: "Products",
      component: Products3,
      permissions: ["menuadmin_products"],
    },
    {
      path: "/recordproductcosts",
      name: "Record Product Costs",
      component: RecordProductCosts,
      permissions: ["menuadmin_recordproductcosts"],
    },

    {
      path: "/roles",
      name: "Roles",
      component: Roles,
      permissions: ["menuadmin_roles"],
    },
    {
      path: "/room-services",
      name: "Room Services",
      component: RoomServices,
      permissions: ["menuadmin_roomservices"],
    },
    {
      path: "/room-states",
      name: "Room States",
      component: RoomStates,
      permissions: ["menuadmin_roomstates"],
    },
    {
      path: "/rooms",
      name: "Rooms",
      component: Rooms,
      permissions: ["menuadmin_rooms"],
    },
    {
      path: "/admin-payrollConfigurations",
      name: "Sales Payroll Configurations",
      component: PayrollConfigurationsTabs,
      permissions: ["menuadmin_payrollConfigurations"],
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      permissions: ["menuadmin_settings"],
    },
    {
      path: "/smsmmstemplates",
      name: "SMS/MMS Templates",
      component: SMSMMSTemplates,
      permissions: ["menuadmin_smsmmstemplates"],
    },
    {
      path: "/sources",
      name: "Sources",
      component: Sources,
      permissions: ["menuadmin_sources"],
    },
    {
      path: "/tags",
      name: "Tags",
      component: Tags,
      permissions: ["menuadmin_tags"],
    },
    {
      path: "/ticket-categories",
      name: "Ticket Categories",
      component: TicketCategories,
      permissions: ["menuadmin_ticketcategories"],
    },
    {
      path: "/ticket-priority",
      name: "Ticket Priority",
      component: TicketPriority,
      permissions: ["menuadmin_ticketpriority"],
    },
    {
      path: "/users",
      name: "Users",
      component: Users2,
      permissions: ["menuadmin_users"],
    },
    {
      path: "/user-change-roles-reasons",
      name: "User Change Roles Reasons",
      component: UserChangeRolesReasons,
      permissions: ["menuadmin_userChangeRolesReasons"],
    },
    {
      path: "/userslogins",
      name: "Users Logins",
      component: UsersLogins,
      permissions: ["menuadmin_userslogins"],
    },
    {
      path: "/whatsAppApplication",
      name: "WhatsApp Applications",
      component: WhatsAppApplication,
      permissions: ["menuadmin_whatsAppApplication"],
    },
    {
      path: "/whatsAppConfiguration",
      name: "WhatsApp Configurations",
      component: WhatsAppConfiguration,
      permissions: ["menuadmin_whatsappConfiguration"],
    },
    {
      path: "/whatsAppGroup",
      name: "WhatsApp Groups",
      component: WhatsAppGroup,
      permissions: ["menuadmin_whatsAppGroup"],
    },
    {
      path: "/messagingreport",
      name: "Messaging Report",
      component: SMSMMSCampaignReport,
      permissions: ["menuadmin_messagingreport"],
    },
    {
      path: "/campaign-credits",
      name: "Campaign Credits",
      component: CampaignCredits,
      permissions: ["menuadmin_campaigncredits"],
    },
    {
      path: "/patient-welcome-templates",
      name: "Patient Welcome Templates",
      component: PatientWelcomeTemplates,
      permissions: ["menuadmin_patientwelcometemplates"],
    },
    {
      path: "/white-list-numbers-for-campaigns",
      name: "White List of Numbers For Campaigns",
      component: WhiteListPhoneNumbers,
      permissions: ["menuadmin_whitelistcampaignnumbers"],
    },
    {
      path: "/coupons",
      name: "Coupons",
      component: Coupons,
      permissions: ["menuadmin_coupons"],
    },
  ],
  component: null,
};

// Old Report Menu
const reportRoutes = {
  id: "Reports General",
  icon: <AssessmentIcon />,
  permissions: ["menu_ReportsMenu"],

  children: [
    {
      path: "/quotesreport",
      name: "Quotes Report",
      component: QuotesReport2,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/invoicesreport",
      name: "Invoices Report",
      component: InvoicesReport2,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/invoicesnew2",
      name: "Invoices Page",
      component: InvoicesPage2,
      roles: ["Permission_DeveloperTeam"],
    },
    {
      path: "/invoiceswithincludereport",
      name: "Package Costs for Invoices",
      component: InvoicesToApplyDiscountReport,
      permissions: ["user_applydoctordiscount"],
    },
    {
      path: "/invoicespaymentreport",
      name: "Invoices Payment Report",
      component: InvoicesPayment,
      roles: ["admin", "super-admin", "manager"],
    },
    {
      path: "/payrollreports",
      name: "Doctors Payroll Report",
      component: PayrollReports3,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/payrollgroupedreport",
      name: "Payroll Report By Patient",
      component: PayrollGroupedReports,
      permissions: ["reports_payrollgrouped"],
    },
    {
      path: "/approved-contracts-report",
      name: "Approved Contracts Report",
      component: ContractsReport2New,
      roles: ["manager", "admin", "super-admin"],
    },

    {
      path: "/general-contracts-report",
      name: "General Contracts Report",
      component: MyQuotesReport2New,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/paymentreconciliationreport",
      name: "Payment Reconciliation Report",
      component: PaymentReconciliationReport,
      permissions: ["reports_paymentReconciliationReport"],
    },
    {
      path: "/manual-transaction-reconciliation",
      name: "Manual Transaction Reconciliation",
      component: ManualTransactionReconciliation,
      permissions: ["reports_manualtransactionreconciliation"],
    },
    {
      path: "/paymentreportslist",
      name: "Payments Report",
      component: PaymentReportsList,
      roles: ["admin", "super-admin", "manager", "user"],
    },

    {
      path: "/paymentreportsdetails",
      name: "Payments Report by Details",
      component: PaymentReports,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/paymentreportshistory",
      name: "Payments Report History",
      component: PaymentReportsHistory,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/paymentreportscash",
      name: "Payments Report Cash by Patients",
      component: PaymentReportsCash,

      roles: ["admin", "super-admin", "manager"],
    },
    {
      path: "/lead-distribution",
      name: "Lead Distribution",
      component: ContactDistribution,
      roles: ["admin", "super-admin"],
    },

    {
      path: "/procedurereports",
      name: "Procedure Report",
      component: RecordProductReports6,
      permissions: ["reports_procedure"],
    },
    {
      path: "/procedurereportsdashboard",
      name: "Procedure Report Dashboard",
      component: ProcedureReportDashboard,
      permissions: ["reports_procedureReportsDashboard"],
    },
    {
      path: "/pendingtoreschedulereports",
      name: "Pending To Reschedule Report",

      component: PendingToRescheduleReportsCoordination,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/duplicatecontactsreport",
      name: "Duplicate Contacts Report",
      component: DuplicateContactsReport,
      roles: ["admin", "super-admin", "manager"],
    },
    // {
    //   path: "/commissionrulesreport",
    //   name: "Commission Reports",
    //   component: CommissionRulesReport,
    //   roles: ["admin", "super-admin", "manager"],
    // },
    {
      path: "/general-recordproducttransfercord",
      name: "Coordination Transfers Report",
      component: RecordProductTransferReportsCoordination,
      permissions: ["reports_recordproducttransfer"],
    },

    {
      path: "/recordproducttransfer",
      name: "Surgeries Transfer Report",
      component: RecordProductTransferReports,
      permissions: ["recordproduct_supertransfer", "recordproduct_transfer"],
    },

    {
      path: "/paymentsactivityreports",
      name: "Payment Activity Report",
      component: PaymentsActivityReportsNew,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/payrollassistantsreports",
      name: "Payroll Assistant Report",
      component: PayrollAssistantsReports,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/payrollanesthesiologistsreports",
      name: "Payroll Anesthesiologists Report",
      component: PayrollAnesthesiologistsReports,
      roles: ["admin", "super-admin"],
    },
    {
      path: "/user-phone-summary-report",
      name: "Call Summary Report",
      component: userPbxSummaryReport,
      permissions: ["user_pbxInformation"],
    },
    {
      path: "/consultantssalesreport",
      name: "Consultants Sales Report",
      component: ConsultantsSalesReport,
      permissions: ["reports_consultantssales"],
    },
    {
      path: "/surgeoncontracts",
      name: "Surgeon Contracts Report",
      component: SurgeonQuotesRecordProductReports,
      permissions: ["reports_surgeoncontracts"],
    },

    {
      path: "/massagescontrolreport",
      name: "Massages Control Report",
      component: ContactsMassagesControlReport,
      permissions: ["reports_massagescontrol"],
    },
    {
      path: "/refundslist",
      name: "Refunds List",
      component: RefundChargeBackReport,
      permissions: ["reports_refundchargeback"],
    },

    {
      path: "/pollreports",
      name: "Polls reports",
      component: pollReport,
      hasInside: true,
      permissions: ["reports_pollreports"],
    },
    {
      path: "/pollpuntuationreports",
      name: "Contact valoration reports",
      component: pollPuntuationReport,
      hasInside: true,
      permissions: ["reports_pollpuntuationreports"],
    },
    {
      path: "/ticketsreport",
      name: "Tickets Report",
      component: TicketsReport,
      permissions: ["reports_tickets"],
    },
    {
      path: "/salesReport",
      name: "Sales Report",
      component: SalesReportsNew2,
      permissions: ["reports_sales"],
    },
    {
      path: "/salesReportWithDoctor",
      name: "Sales Report With Doctor",
      component: SalesReportsNewWithDoctor,
      permissions: ["reports_saleswithdoctor"],
    },
    {
      path: "/summaryclosingrate",
      name: "Summary Closing Rate",
      component: TopBottomSellers,
      permissions: ["reports_sellerssummary"],
    },
    {
      path: "/productsControl",
      name: "Products Control",
      component: ContactsProductsNoProcedure,
      permissions: ["reports_productscontrol"],
    },
    {
      path: "/productgiftreport",
      name: "Product Gift Report",
      component: ProductGiftReport,
      permissions: ["reports_giftreport"],
    },

    {
      path: "/creditreport",
      name: "Credit Report",
      component: CreditReport,
      permissions: ["reports_creditReport"],
    },
    {
      path: "/reportedcontacts",
      name: "Reported Contacts",
      component: BadContactReport,
      permissions: ["reports_badcontacts"],
    },
    {
      path: "/procedurecanceledreports",
      name: "Procedure Canceled Report",
      component: ProcedureCanceledReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/procedurerefundreports",
      name: "Procedure Refunded Report",
      component: ProcedureRefundReportsCoordination,
      permissions: ["reports_pending"],
    },
    {
      path: "/contactspatientsbyzipcode",
      name: "Patients By ZipCode",
      component: ContactsPatientsStateZipCode,
      permissions: ["reports_contactrecordzip"],
    },
    // only for Miami Life
    {
      path: "/financedpatients",
      name: "Financed Patients",
      component: FinancedPatients,
      permissions: ["reports_financedPatients"],
    }, // only for Miami Life
    {
      path: "/accountingsalesreportsuper",
      name: "Accounting Sales Report",
      component: AccountingSalesReport,
      permissions: ["reports_accountingSalesReport"],
    },
    {
      path: "/accountingsalesreportbyperiod",
      name: "Accounting Sales Report By Period",
      component: AccountingSalesReportNew,
      permissions: ["reports_accountingSalesReport"],
    },

    {
      path: "/coordinator-approved-contracts-report",
      name: "Approved Contracts Report",
      component: ContractsReport2New,
      permissions: ["dashboard_coordinationBasic"],
    },
    {
      path: "/patientsDeposit",
      name: "Patients Deposit",
      component: RecordsDeposit,
      permissions: ["reports_recordsDeposit"],
    },
    {
      path: "/fraudulentcontacts",
      name: "Fraudulent Contacts",
      component: FraudolentContacts,
      permissions: ["reports_fraudolentcontacts"],
    },
    {
      path: "/records-transfermark",
      name: "Transferred Patients",
      component: RecordTransfersMark,
      permissions: ["reports_transferPatientsDoctor"],
    },
    {
      path: "/firstdeposit",
      name: "First Deposit",
      component: FirstDepositReport,
      permissions: ["reports_firstdeposit"],
    },
    {
      path: "/invoiceslogs",
      name: "Invoices Logs",
      component: InvoicesLogs,
      permissions: ["invoices_viewLogs"],
    },
    {
      path: "/surgerieslogs",
      name: "Surgeries Logs",
      component: RecordProductsLogs,
      permissions: ["recordproduct_viewLogs"],
    },
    {
      path: "/surgerieslogsgrouped",
      name: "Surgeries Logs Grouped",
      component: RecordProductsLogsGrouped,
      permissions: ["recordproduct_viewLogs"],
    },
    {
      path: "/surgerieslogsbyhistory",
      name: "Surgeries Logs By History",
      component: RecordProductsLogsByHistory,
      permissions: ["recordproduct_viewLogs"],
    },
    {
      path: "/chargebackstatesreport",
      name: "Chargeback Report",
      component: ChargeBackReport,
      permissions: ["reports_refundchargeback"],
    },
    {
      path: "/userchangerolesreport",
      name: "User Change Roles Report",
      component: UserChangeRolesReport,
      permissions: ["reports_userChangeRolesHistory"],
    },
    {
      path: "/invoicesForfeiture",
      name: "Invoices Forfeiture Report",
      component: InvoicesForfeitureReport,
      permissions: ["reports_invoiceForfeiture"],
    },
    {
      path: "/quotediscountreport",
      name: "Quotes Discount Report",
      component: QuoteDiscountReport,
      permissions: ["reports_quoteDiscountReport"],
    },
    {
      path: "/invoicediscountreport",
      name: "Invoices Discount Report",
      component: InvoiceDiscountReport,
      permissions: ["reports_invoiceDiscountReport"],
    },
    {
      path: "/productsservicesreport",
      name: "Products Services Report",
      component: ProductsServicesReport,
      permissions: ["reports_productsServicesReport"],
    },
    {
      path: "/useractivities",
      name: "User Activities Report",
      component: UserActivityReport,
      permissions: ["reports_useractivity"],
    },
    {
      path: "/invalid-phone-numbers",
      name: "Invalid Phones",
      component: InvalidPhoneNumbersReport,
      permissions: ["reports_invalidphonenumbers"],
    },
    {
      path: "/merchant-transactions-reconciliation",
      name: "Merchant Transactions Reconciliation",
      component: PaymentsConciliationReport,
      permissions: ["reports_paymentsconciliation"],
    },
    // {
    //   path: "/contactactivityreport",
    //   name: "Contact Activity Report",
    //   component: ContactActivityReport,
    //   roles: ["Permission_DeveloperTeam"],
    //   // permissions: ["reports_productsServicesReport"],   // new permission
    // },
  ],
  component: null,
};

// Old Report2 Menu
const reportRoutesByPermisions = {
  id: "Reports",
  icon: <AssessmentIcon />,
  permissions: ["reports_inleads"],

  children: [
    {
      path: "/lead-distribution-bypermisions",
      name: "Lead Distribution",
      component: ContactDistribution,

      permissions: ["reports_inleads"],
    },
  ],
  component: null,
};

//Old Coordination
const coordinationRoutes = {
  id: "Coordination",
  icon: <AssignmentIcon />,
  permissions: ["menu_CoordinationMenu"],

  children: [
    {
      path: "/records",
      name: "Records",
      component: Records2,
    },
    {
      path: "/coordination/contacts/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendarCoordination,
    },

    {
      path: "/invoices",
      name: "Invoices",
      component: Invoicesnew,
    },
    {
      path: "/timeline",
      name: "Calendar",
      component: ProcedureTimeline5,
    },

    {
      path: "/refundrequests",
      name: "Refund Requests",
      component: RefundRequests2,
      permissions: ["refundrequest_create"],
    },
    {
      path: "/doctorlist",
      name: "Doctor List",
      component: DoctorList,
      permissions: ["user_Supercoordinator"],
    },
    {
      path: "/procedurereportscoordination",
      name: "Procedure Report",
      component: RecordProductReports6,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/pendingtoschedulereportscoordination",
      name: "Pending To Schedule Report",
      component: PendingToScheduleReportsCoordination,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/pendingtoreschedulereportscoordination",
      name: "Pending To Reschedule Report",
      component: PendingToRescheduleReportsCoordination,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/procedurecanceledreportscoordination",
      name: "Procedure Canceled Report",
      component: ProcedureCanceledReportsCoordination,
      roles: ["manager", "admin", "super-admin"],
    },
    {
      path: "/recordproductdatetransfers",
      name: "Surgery Scheduling Requests",
      component: RecordProductDateTransferReport,
      permissions: [
        "recordproduct_transferdate",
        "recordproduct_supertransferdate",
        "recordproduct_coordinatormanagertransferdate",
      ],
    },
  ],
  component: null,
};

//Old CRM
const CRMRoute = {
  id: "CRM",
  icon: <PeopleIcon />,
  permissions: ["menu_CRMMenu"],
  children: [
    {
      path: "/contacts",
      name: "Contacts",
      component: Contacts3,
    },
    {
      path: "/contacts/todo/calendar",
      name: "To Do Calendar",
      component: ToDoCalendar,
    },
    {
      path: "/my-approved-contracts",
      name: "My Approved Contracts",
      component: MyContractReport,
    },
    {
      path: "/my-general-contracts-report",
      name: "My General Contracts Report",
      component: MyQuotesReport3,
    },

    {
      path: "/doctor-heatmap",
      name: "Doctor Heatmap",
      component: DoctorHeatMap,
    },
    {
      path: "/consultantslist",
      name: "Consultants List",
      component: ConsultantsList,
      permissions: ["contacts_teamleader", "user_salesGroupManager"],
    },
    {
      path: "/mysellersconfiguration",
      name: "Configuration of my sellers",
      component: HolidaysConfiguration,
      permissions: ["user_configuresellers"],
    },
    {
      path: "/payment-link-requests",
      name: "Payment Link Requests",
      component: PaymentLinkRequests,
      permissions: ["paymentlinkrequest_showMenu"],
    },
  ],
  component: null,
};

//OLD Routes without childrens //

// ******************************** //

const profileRoute = {
  id: "Profile",
  path: "/profile",
  component: Profile,
};
const quoteRoute = {
  path: "/quotes/:id",
  component: Quotes,
};
const invoiceRoute = {
  path: "/invoices/:id",
  component: Invoices,
};
const invoice2Route = {
  path: "/invoices2/:id",
  component: Invoices2,
};
const contactRoute = {
  path: "/contacts/:id",
  component: Contacts,
};
const contactViewRoute = {
  path: "/contacts/:id/view",
  component: ContactView,
};
const contact2Route = {
  path: "/contacts2/:id",
  component: Contacts2,
};
const contact2ViewRoute = {
  path: "/contacts2/:id/view",
  component: ContactView,
};
const recordRoute = {
  path: "/records/:id",
  component: Records,
};
const messageRoute = {
  path: "/messagessms/:newphone",
  component: MessagesSMS,
  // roles: ["super-admin", "SuperAdmin"],
};
const ticketsagentRoute = {
  path: "/ticketsagentdate/:fromdate/:todate",
  component: TicketsAgent,
};
const callshistoryRoute = {
  path: "/callshistorydate/:filter/:fromdate/:todate",
  component: UserCallHistory,
};
const userCallHistoryRouteFilter = {
  path: "/unreadmissedcalls/:filter",
  component: UserUnreadMissedCalls,
};
const userCallHistoryRoute = {
  path: "/unreadmissedcalls",
  component: UserUnreadMissedCalls,
  // roles: ["super-admin", "SuperAdmin"],
};
const contactsP = {
  path: "/crmcontacts",
  component: ContactsP,
  roles: ["super-admin", "SuperAdmin"],
};
const customFieldsModel = {
  path: "/custom-fields/:model",
  component: CustomFields,
};
const taskRoute = {
  path: "/tasks",
  component: Tasks,
  roles: ["super-admin", "SuperAdmin"],
};
const clinicFlowRoute = {
  path: "/clinic-flow",
  component: ClinicFlow,
  roles: ["super-admin", "SuperAdmin"],
};
const contacts2Route = {
  path: "/contacts2",
  component: Contacts2,
  roles: ["super-admin", "SuperAdmin"],
};
const contacts3Route = {
  path: "/contacts3",
  component: Contacts3,
  roles: ["super-admin", "SuperAdmin"],
};
const invoicesnewRoute = {
  path: "/invoicesnew",
  component: Invoicesnew,
  roles: ["super-admin", "SuperAdmin"],
};
const contactsnewRoute = {
  path: "/contactsnew",
  component: Contacts3,
  roles: ["super-admin", "SuperAdmin"],
};
const topBottomSellers = {
  path: "/sellerssummary2",
  component: TopBottomSellers,
  roles: ["super-admin", "SuperAdmin"],
};
const clientsList = {
  path: "/clientssummary2",
  component: ClientsList,
  roles: ["super-admin", "SuperAdmin"],
};
const deactivateHistory = {
  path: "/deactivatehistory1",
  component: DeactivateHistory,
  roles: ["super-admin", "SuperAdmin"],
};
const smsmmsCampaign = {
  path: "/smsmmscampaign",
  component: SMSMMSCampaignReport,
  roles: ["super-admin", "SuperAdmin"],
};
const appointmentTypes = {
  path: "/appointmentTypess",
  component: AppointmentTypes,
  roles: ["super-admin", "SuperAdmin"],
};
const comparativeSalesAnalysis = {
  path: "/comparativesalesanalysis2",
  component: ComparativeSalesAnalysis,
  roles: ["super-admin", "SuperAdmin"],
};
const accountingSalesReportTwoYear = {
  path: "/accountingsalesreporttwoyear2",
  component: AccountingSalesReport3,
  roles: ["super-admin", "SuperAdmin"],
};
const patientssDeposit = {
  path: "/patientsDeposit2",
  component: RecordsDeposit,
  roles: ["super-admin", "SuperAdmin"],
};
const timeline6Route = {
  id: "Calendar",
  path: "/timeline6",
  component: ProcedureTimeline6,
  roles: ["super-admin"],
};
const productsControlRoute = {
  path: "/productsControlnew",
  component: ContactsProductsNoProcedureNew,
  roles: ["super-admin"],
};

const productsServicesReport2 = {
  path: "/productsservicesreportnew2",
  component: ProductsServicesReport2,
  roles: ["super-admin"],
};
const salesReportsNewRoute = {
  path: "/salesReportnew2",
  component: SalesReportsNew2,
  roles: ["super-admin"],
};
const appointmentNewDashboard = {
  path: "/appointmentsnewdashboards",
  component: AppointmentNewDashboard,
  roles: ["super-admin", "SuperAdmin"],
};
const appointmentsReport = {
  path: "/appointmentsreports",
  component: AppointmentsReport,
  roles: ["super-admin", "SuperAdmin"],
};
const appointmentNew = {
  path: "/appointmentsnews",
  component: AppointmentNew,
  roles: ["super-admin", "SuperAdmin"],
};
const deactivateReasons = {
  path: "/deactivatereasons1",
  component: DeactivateReasons,
  roles: ["super-admin", "SuperAdmin"],
};
const paymentReports2 = {
  path: "/paymentreports2",
  component: PaymentReports2,
  roles: ["super-admin", "SuperAdmin"],
};
const paymentReportsDev = {
  path: "/paymentreportsdev",
  component: PaymentReportsDev,
  roles: ["super-admin", "SuperAdmin"],
};
const paymentReportsNewByInvoices = {
  path: "/paymentreportsnewbyinvoices2",
  component: PaymentReportsNewByInvoices,
  roles: ["super-admin", "SuperAdmin"],
};
const paymentReportsCash = {
  path: "/paymentreportscash",
  component: PaymentReportsCash,
  roles: ["super-admin", "SuperAdmin"],
};
const calendarHeatmap = {
  path: "/calendarheatmap",
  component: CalendarHeatmap,
  roles: ["super-admin", "SuperAdmin"],
};
const recordProductReports2 = {
  path: "/procedurereports2",
  component: RecordProductReports2,
  roles: ["super-admin", "SuperAdmin"],
};
const quotesReport2 = {
  path: "/quotesreport2",
  component: QuotesReport2,
  roles: ["super-admin", "SuperAdmin"],
};
const invoicesReport2 = {
  path: "/invoicesreport2",
  component: InvoicesReport2,
  roles: ["super-admin", "SuperAdmin"],
};
const invoices2 = {
  path: "/invoices2",
  component: Invoices2,
  roles: ["super-admin", "SuperAdmin"],
};
const records2 = {
  path: "/records2",
  component: Records2,
  roles: ["super-admin", "SuperAdmin"],
};
const dashboard2 = {
  path: "/dashboard2",
  component: Dashboard2,
  roles: ["super-admin", "SuperAdmin"],
};
const dashboard2a = {
  path: "/dashboard2a",
  component: Dashboard2a,
  roles: ["super-admin", "SuperAdmin"],
};
const dashboardRegular = {
  path: "/dashboardR",
  component: DashboardRegular,
};
const pendingToRescheduleReports2 = {
  path: "/pendingtoreschedulereports2",
  component: PendingToRescheduleReports2,
  roles: ["super-admin", "SuperAdmin"],
};
const records3 = {
  path: "/records3",
  component: Records3,
  roles: ["super-admin", "SuperAdmin"],
};
const procedureHeatmapRoute = {
  path: "/procedure-heatmap",
  component: ProcedureHeatmap,
  roles: ["super-admin", "SuperAdmin"],
};
const commissionReportsRoute = {
  path: "/commission-report",
  component: CommissionReports,
  roles: ["super-admin", "SuperAdmin"],
};
const salesReportsRoute = {
  path: "/sales-report",
  component: SalesReports,
  roles: ["super-admin", "SuperAdmin"],
};
const roundRobinRoute = {
  path: "/round-robin",
  component: Roundrobin,
  roles: ["super-admin", "SuperAdmin"],
};
const procedureTimelineRoute2 = {
  path: "/timeline2",
  component: ProcedureTimeline2,
  roles: ["super-admin", "SuperAdmin"],
};
const contactDistribution = {
  path: "/lead-distribution2",
  component: ContactDistribution,
  roles: ["super-admin", "SuperAdmin"],
};
const procedureTimelineRoute4 = {
  path: "/timeline4",
  component: ProcedureTimeline4,
  roles: ["super-admin", "SuperAdmin"],
};
const procedureTimelineRoute5 = {
  path: "/timeline5",
  component: ProcedureTimeline5,
  roles: ["super-admin", "SuperAdmin"],
};
const myContractReport2 = {
  path: "/my-approved-contracts2",
  component: MyContractReport2,
  roles: ["super-admin", "SuperAdmin"],
};
const contractsReport2 = {
  path: "/contractsreport2",
  component: ContractsReport2,
  roles: ["super-admin", "SuperAdmin"],
};
const payrollReports2 = {
  path: "/payrollreports2",
  component: PayrollReports2,
  roles: ["super-admin", "SuperAdmin"],
};
const payrollReports3 = {
  path: "/payrollreports3",
  component: PayrollReports3,
  roles: ["super-admin", "SuperAdmin"],
};
const payrollAssistantsReports = {
  path: "/payrollassistantsreports",
  component: PayrollAssistantsReports,
  roles: ["super-admin", "SuperAdmin"],
};
const paymentsActivityReportsnew = {
  path: "/paymentsactivityreportsnew",
  component: PaymentsActivityReportsNew,
  roles: ["super-admin", "SuperAdmin"],
};
const products3 = {
  path: "/products3",
  component: Products3,
  roles: ["super-admin", "SuperAdmin"],
};
const topSellersRoute = {
  path: "/top-sellers",
  component: TopSellers,
  roles: ["super-admin", "SuperAdmin"],
};
const SMSMMSTemplatesRoute = {
  path: "/smsmmstemplatess",
  component: SMSMMSTemplates,
  roles: ["super-admin", "SuperAdmin"],
};
const AppointmentsTodayListRoute = {
  path: "/appointmentstodaylist",
  component: AppointmentsTodayList,
  roles: ["super-admin", "SuperAdmin"],
};
const topSellersOrgRoute = {
  path: "/top-sellers-organization",
  component: TopSellersOrg,
  roles: ["super-admin", "SuperAdmin"],
};
const chatRoute = {
  path: "/chat",
  component: Chat,
  roles: ["super-admin", "SuperAdmin"],
};
const ticketCategoriesRoute = {
  path: "/ticket-categories2",
  component: TicketCategories,
  roles: ["super-admin", "SuperAdmin"],
};
const ticketPriorityRoute = {
  path: "/ticket-priority2",
  component: TicketPriority,
  roles: ["super-admin", "SuperAdmin"],
};
const ticketsRoute = {
  path: "/tickets2",
  component: Tikets,
  roles: ["super-admin", "SuperAdmin"],
};
const tiketsNewRoute = {
  path: "/ticketsnew",
  component: TiketsNew,
  roles: ["super-admin"],
};
const recordSearchRoute = {
  path: "/record_search",
  component: RecordSearch2,
  roles: ["super-admin", "SuperAdmin"],
};
const topDoctorsRoute = {
  path: "/top-doctors",
  component: TopDoctors,
  roles: ["super-admin", "SuperAdmin"],
};
const approveContractByConsultantBuilding = {
  path: "/approved-contracts-reportv3",

  component: ContractsReportByConsultantBuilding,
  roles: ["super-admin", "SuperAdmin"],
};
const doctorList = {
  path: "/doctorlist2",

  component: DoctorList,
  roles: ["super-admin", "SuperAdmin"],
};
const users2 = {
  path: "/users2",

  component: Users2,
  roles: ["super-admin", "SuperAdmin"],
};
const portalAuthDashboardRoute = {
  path: "/portal/:token/auth",
  component: PortalAuth,
};
const portalForgetDashboardRoute = {
  path: "/portal/:token/forget",
  name: "Forget Password",
  component: PortalForgetPass,
};
const portalCreateDashboardRoute = {
  path: "/portal/:token/createpassword",
  name: "Create Password",
  component: PortalForgetPass,
};
const portalChangeDashboardRoute = {
  path: "/portal/:token/changepsw/:uuid",
  name: "Change Password",
  component: PortalChangePsw,
};
const portalDashboardRoute = {
  path: "/portalOld",
  component: PortalIndex,
  roles: ["super-admin", "SuperAdmin"],
};
const portalDashboardRoute2 = {
  path: "/portal/:token",
  component: PortalIndex2,
  roles: ["super-admin", "SuperAdmin"],
};
const checkInRoute = {
  path: "/checkinappointment",
  component: checkIn,
};
const AppointmentDataCheckINRoute = {
  path: "/checkinappointment/contact",
  component: ContactInformation,
};
const contactsAppointmentsForToday = {
  path: "/checkinappointment/contact/appointments",
  component: ContactAppointmentsForToday,
};
const portalDashboardRoute2TabIndex = {
  path: "/portal2/:TabIndex",
  component: PortalIndex2,
  roles: ["super-admin", "SuperAdmin"],
};
const refundReportsRoute = {
  path: "/refundreports",
  component: RefundReports,
  roles: ["super-admin", "SuperAdmin"],
};
const contactsMassagesControlReport = {
  path: "/massagescontrolreport2",
  component: ContactsMassagesControlReport,
  roles: ["super-admin", "SuperAdmin"],
};
const consultantsSalesReport = {
  path: "/consultantssalesreport",
  component: ConsultantsSalesReport,
  roles: ["super-admin", "SuperAdmin"],
};
const surgeonQuotesRecordProductReports = {
  path: "/surgeoncontracts",
  component: SurgeonQuotesRecordProductReports,
  roles: ["super-admin", "SuperAdmin"],
};
const recordProductReports6 = {
  path: "/procedurereports6",
  component: RecordProductReports6,
  roles: ["super-admin", "SuperAdmin"],
};
const contactsPlain = {
  path: "/contactsplain",
  component: Contacts4,
  roles: ["super-admin", "SuperAdmin"],
};
const salesReportsNew = {
  path: "/salesReport2",
  component: SalesReportsNew,
  roles: ["super-admin", "SuperAdmin"],
};
const salesReportsNewWithDoctor = {
  path: "/salesReportWithDoctor",
  component: SalesReportsNewWithDoctor,
  roles: ["super-admin", "SuperAdmin"],
};
const contactsProductsNoProcedure = {
  path: "/productsControl2",
  component: ContactsProductsNoProcedure,
  roles: ["super-admin", "SuperAdmin"],
};
const ticketsReport = {
  path: "/ticketsreport2",
  component: TicketsReport,
  roles: ["super-admin", "SuperAdmin"],
};
const smsReport = {
  path: "/smsreport2",
  component: SMSReport,
  roles: ["super-admin", "SuperAdmin"],
};
const callsReport = {
  path: "/callsreport2",
  component: CallsReport,
  roles: ["super-admin", "SuperAdmin"],
};
const salesBySourceReport = {
  path: "/salesbysource",
  component: SalesBySourceReport,
  roles: ["super-admin", "SuperAdmin"],
};
const contacts5 = {
  path: "/contactsop",
  component: Contacts5,
  //  roles: ["super-admin", "SuperAdmin"],
};
const payrollGroupedReports = {
  path: "/payrollgroupedreport",
  component: PayrollGroupedReports,
  roles: ["super-admin", "SuperAdmin"],
};
const accountingSalesReport = {
  path: "/accountingsalesreport2",
  component: AccountingSalesReport,
  roles: ["super-admin", "SuperAdmin"],
};
const fraudReasons = {
  path: "/fraudulentcontactsreasons",
  component: FraudReasons,
  roles: ["super-admin", "SuperAdmin"],
};
const accountingSalesReport3 = {
  path: "/accountingsalesreport3",
  component: AccountingSalesReport3,
  roles: ["super-admin", "SuperAdmin"],
};
const genericRoutes = {
  path: "/html/privacy-policy",
  component: PrivacyPage,
  children: null,
};
const unsuscribeRoute = {
  path: "/unsubscribe/:subscription_token",
  component: UnsuscribeContact,
  children: null,
};
const appointmentConfirmation = {
  path: "/appointmentconfirmation",
  component: AppointmentConfirmation,
  children: null,
};
const claimRoute = {
  path: "/claim-credit",
  component: ClaimPage,
};
const specialRoute = {
  path: "/special-sale",
  component: SpecialPage,
};
const productsNoProcedurePayroll = {
  path: "/productsnoprocedurepayroll",
  component: ProductsNoProcedurePayroll,
  permissions: ["reports_productsPayroll"],
};
const reviewInvoice = {
  path: "/reviewinvoice",
  component: ReviewInvoice,
  children: null,
};
const payInvoice = {
  path: "/payinvoice",
  component: PayInvoice,
  children: null,
};

const toDoCalendarCoordinationNew = {
  path: "/contacts-todocalendar",
  component: ToDoCalendarCoordinationNew,
  roles: ["Permission_DeveloperTeam"],
  children: null,
};

// ******************************** //

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  homeRoute,
  dashboardRoute,
  fraudReasons,
  topPlasticRoute,
  CRMRoute,
  SalesMenu2,
  SalesMenu2Report,
  profileRoute,
  timeline6Route,
  productsControlRoute,
  productsServicesReport2,
  salesReportsNewRoute,
  coordinationRoutesTransfer,
  coordinationRoutes2CRM,
  coordinationRoutes,
  coordinationContactsMarkRoutes,
  coordinationRoutes2,
  coordinationRoutes2Report,
  RefundsRoutes,
  TaskRoutes,
  coordinationPARoutes,
  CallCenterMenu2,
  CallCenterMenu2CallCenter,
  CallCenterMenu2Coordination,
  CallCenterMenu2Report,
  FrontDeskMenu2,
  FrontDeskMenu2FrontDesk,
  FrontDeskMenu2Coordination,
  FrontDeskMenu2Report,
  SocialMediaMenu2,
  SocialMediaMenu2SocialMedia,
  SocialMediaMenu2Coordination,
  BillingMenu2,
  BillingMenu2Billing,
  BillingMenu2Coordination,
  BillingMenu2Report,
  users2,
  MarketingRoutes,
  adminRoutes,
  // accountingRoutes,
  reportRoutes,
  reportRoutesByPermisions,
  PayrollCenterRoutes,
  AppointmentsRoutes,
  quoteRoute,
  contactRoute,
  contactViewRoute,
  contact2Route,
  contact2ViewRoute,
  messageRoute,
  ticketsagentRoute,
  callshistoryRoute,
  userCallHistoryRoute,
  userCallHistoryRouteFilter,
  contactsP,
  recordRoute,
  invoiceRoute,
  invoice2Route,
  invoicesnewRoute,
  taskRoute,
  customFieldsModel,
  consultantsSalesReport,
  surgeonQuotesRecordProductReports,
  contacts2Route,
  contacts3Route,
  contactsnewRoute,
  topBottomSellers,
  clientsList,
  appointmentTypes,
  comparativeSalesAnalysis,
  accountingSalesReportTwoYear,
  patientssDeposit,
  appointmentNewDashboard,
  appointmentsReport,
  appointmentNew,
  smsmmsCampaign,
  deactivateHistory,
  deactivateReasons,
  InventoryNewRoutes,
  // inventoryRoutes,
  paymentReports2,
  paymentReportsDev,
  paymentReportsCash,
  salesReportsNew,
  salesReportsNewWithDoctor,
  contacts5,
  payrollGroupedReports,
  productsNoProcedurePayroll,
  toDoCalendarCoordinationNew,
  accountingSalesReport,
  accountingSalesReport3,
  ticketPriorityRoute,
  ticketsRoute,
  tiketsNewRoute,
  ticketCategoriesRoute,
  calendarHeatmap,
  recordProductReports2,
  contactsPlain,
  invoices2,
  procedureHeatmapRoute,
  commissionReportsRoute,
  paymentReportsNewByInvoices,
  salesReportsRoute,
  records2,
  records3,
  procedureTimelineRoute2,
  dashboardRegular,
  appointmentsnew,
  quotesReport2,
  invoicesReport2,
  products3,
  TicketRoutes,
  FinancingRoutes,
  DirectoryRoutes,
  AccountingReportsRoutes,
  contactDistribution,
  procedureTimelineRoute4,
  procedureTimelineRoute5,
  pendingToRescheduleReports2,
  myContractReport2,
  contractsReport2,
  dashboard2,
  dashboard2a,
  payrollReports2,
  payrollAssistantsReports,
  paymentsActivityReportsnew,
  payrollReports3,
  topSellersRoute,
  AppointmentsTodayListRoute,
  SMSMMSTemplatesRoute,
  topSellersOrgRoute,
  chatRoute,
  recordSearchRoute,
  clinicFlowRoute,
  topDoctorsRoute,
  approveContractByConsultantBuilding,
  doctorList,
  AnalyticsRoutes,
  refundReportsRoute,
  messageRoutes,
  contactsMassagesControlReport,
  recordProductReports6,
  ticketsReport,
  smsReport,
  callsReport,
  salesBySourceReport,
  contactsProductsNoProcedure,
  merchantsRoutes,
  ReportsCenterNewRoutes,
  InfluencerMenu,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardRoute,
  topPlasticRoute,
  // dashboardAllBuildingsRoute,
  CRMRoute,
  SalesMenu2,
  SalesMenu2Report,
  coordinationRoutes2CRM,
  coordinationRoutes,
  coordinationContactsMarkRoutes,
  coordinationRoutes2,
  coordinationRoutesTransfer,
  coordinationRoutes2Report,
  RefundsRoutes,
  TaskRoutes,
  coordinationPARoutes,
  CallCenterMenu2,
  CallCenterMenu2CallCenter,
  CallCenterMenu2Coordination,
  CallCenterMenu2Report,
  FrontDeskMenu2,
  FrontDeskMenu2FrontDesk,
  FrontDeskMenu2Coordination,
  FrontDeskMenu2Report,
  SocialMediaMenu2,
  SocialMediaMenu2SocialMedia,
  SocialMediaMenu2Coordination,
  BillingMenu2,
  BillingMenu2Billing,
  BillingMenu2Coordination,
  BillingMenu2Report,
  reportRoutes,
  reportRoutesByPermisions,
  PayrollCenterRoutes,
  TicketRoutes,
  FinancingRoutes,
  DirectoryRoutes,
  AccountingReportsRoutes,
  messageRoutes,
  AnalyticsRoutes,
  appointmentsnew,
  AppointmentsRoutes,
  MarketingRoutes,
  // accountingRoutes,
  // inventoryRoutes,
  InventoryNewRoutes,
  merchantsRoutes,
  ReportsCenterNewRoutes,
  InfluencerMenu,
  adminRoutes,
];

export const portalLayoutRoutes = [portalDashboardRoute];
export const portalLayoutRoutes2 = [portalDashboardRoute2];
export const checkInAppointmentRoute = [
  checkInRoute,
  AppointmentDataCheckINRoute,
  contactsAppointmentsForToday,
];
export const portallayoutRoutes2TabIndex = [portalDashboardRoute2TabIndex];
export const portalAuthLayoutRoutes = [
  portalAuthDashboardRoute,
  portalForgetDashboardRoute,
  portalCreateDashboardRoute,
  portalChangeDashboardRoute,
];
export const genericLayoutRoutes = [genericRoutes];
export const unsuscribeLayoutRoutes = [unsuscribeRoute];
export const appointmentConfirmationLayout = [appointmentConfirmation];

export const merchantLayoutRoutes = [reviewInvoice, payInvoice];

export const noLogoLayoutRoutes = [claimRoute, specialRoute];
